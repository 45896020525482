import { get } from './index.js';

/**
 * Makes an API call to the GET /groups resource
 *    getGroups("asdf", ['Client', Industry Contact']) =>
 *      /groups?search="{searchText}"&type={types[0]}&type={types[1]} =>
 *      /groups?search="asdf"&type=Client&type=Industry%20Contact
 *
 * @param searchText: The text to search for in groups - exact search is automatically applied here by surrounding the search text with double-quotes
 * @param types: The types of groups to search for: Client, Industry Contact, Shared Contact
 * @param role:  The role the company provides:
 *                  - projects.studio
 *                  - projects.network
 *                  - projects.productionCompany
 *                  - projects.promototer
 *                  - projects.parentCompany
 *                  - projects.talentAgency
 *                  - projects.financing
 *                  - projects  *supplying a role of "projects" string returns groups with any projects role
 *
 * @returns A list of groups from the response payload (no meta result yet)
 **/
export const getGroups = (searchText, { types = [], role }, _get = get) => {
  const query = [{ sort: 'name' }];
  if (searchText) {
    //wrap search text in double quotes to force an exact match search
    query.push({ search: `"${searchText}"` });
  }
  types.forEach(t => {
    query.push({ type: t });
  });

  if (role) {
    //use the has operator to deterimine if a group contains a role
    query.push({ 'roles[has]': role });
  }

  const execPlan = _get(`/groups`, query).then(response => {
    const { errors, data, meta } = (response || {}).body || {};
    if (errors) {
      //errors is an array, but v3 only returns a single error in the array
      //this is for api-handled errors,
      //  - unhandled errors shouldnt get this far (such as network outage)
      console.error('Handled API Error from /groups', errors);
      return Promise.reject(errors[0]);
    }
    const results = { data, meta };
    return results;
  });
  return execPlan;
};

export const groupRoles = {
  Studio: 'projects.studio',
  Studios: 'projects.studio',
  Network: 'projects.network',
  Networks: 'projects.network',
  'Production Co.': 'projects.productionCompany',
  'Production Companies': 'projects.productionCompany',
  Promoters: 'projects.promoter',
  'Parent Companies': 'projects.parentCompany',
  'Talent Agencies': 'projects.talentAgency',
  Financing: 'projects.financing',
  AnyRole: 'projects',
};
