import React, { Component } from 'react';
import { helpers, styled } from 'react-free-style';
import { connect } from 'react-redux';
import { loadTrackings } from '@united-talent-agency/julius-frontend-store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';

import { colors, icons, sizes } from '@united-talent-agency/julius-frontend-components';

class Trackings extends Component {
  // eslint-disable-next-line no-undef
  state = {
    isAddOpen: false,
    isEdit: false,
    focused: 0,
    trackings: [],
  };

  componentDidMount() {
    this.props.dispatch(loadTrackings(null, null, this.props.projects.project._id)).then(result => {
      this.setState({ trackings: result ? result.body : [] });
    });
  }

  render() {
    const { styles } = this.props;
    const { trackings } = this.state;
    return (
      <div>
        <table id={'trackingTable'} className={styles.table}>
          <thead>
            <tr className={styles.th}>
              <th className={styles.thText}>List Name</th>
              <th className={styles.thText}>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {_.orderBy(trackings, ['updatedAt', 'name'], ['desc', 'asc']).map((tracking, index) => {
              return (
                <tr className={styles.td} key={index}>
                  <td>
                    <span className={styles.name}>
                      <Link to={{ pathname: `/list/${tracking._id}` }} target={tracking._id} className={styles.link}>
                        {tracking.name}
                      </Link>
                    </span>
                  </td>
                  <td className={styles.modified}>
                    {tracking.updatedAt ? moment(tracking.updatedAt).format('YYYY-MM-DD') : '?'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  addContainer: helpers.merge(sizes.container, {
    paddingTop: 25,
    background: 'white',
    border: '1px black solid',
  }),
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  starActiveIcon: icons.starActive,
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  loading: {
    textAlign: 'center',
    padding: 10,
  },
  menu: helpers.merge(
    {
      display: 'flex',
      marginBottom: 20,
    },
    sizes.container
  ),

  link: {
    cursor: 'pointer',
    color: '#000',
  },
  td: {
    '> td': {
      padding: 10,
      fontSize: 11,
      fontWeight: 300,
      color: colors.text,
    },
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  const { user, projects } = state;

  return {
    user: user.user,
    projects: projects,
  };
});

export default withState(withStyles(Trackings));
