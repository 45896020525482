import React, { Component } from 'react';

class ActionButtons extends Component {
  render() {
    const { buttons } = this.props;

    if (!buttons) {
      return null;
    }

    return (
      <div style={{ float: 'right', textAlign: 'right', marginBottom: '5px' }}>
        {buttons.map((button, index) => (
          <div
            key={index}
            onClick={button.disabled ? null : button.onClick}
            style={{
              fontSize: 12,
              padding: '5px',
              marginLeft: '5px',
              textTransform: 'uppercase',
              display: button.hidden ? 'none' : 'inline-block',
              cursor: button.disabled ? 'default' : 'pointer',
              color: button.disabled ? 'gray' : 'black',
            }}
          >
            <i className={`fa fa-w fa-${button.icon}`} style={{ marginRight: '5px' }} />
            <span>{button.name}</span>
          </div>
        ))}
      </div>
    );
  }
}

export default ActionButtons;
