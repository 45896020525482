import React, { useEffect, useState } from 'react';
import { helpers } from 'react-free-style/dist/react-free-style';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';

import { colors, icons, sizes } from '@united-talent-agency/julius-frontend-components';
import { listHistoryProject, projectLoadRoles, getProjectCastings } from '@united-talent-agency/julius-frontend-store';

import History from '../../../../../components/history/history';

const Activity = ({ dispatch, projects, styles }) => {

  const [history, setHistory] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [castings, setCastings] = React.useState([]);

  const changeAuspiceHistory = (history, _id) => {
    if(!history) return [];
    return history.map(entry => {
      if(!entry.delta){
        entry.delta = {
          created: [_id]
        };
      }
      for (const [key, value] of Object.entries(entry.delta)) {
        if(key !== 'userId'){
          entry.delta[`Auspice: ${key}`] = value;
          delete entry.delta[key];
        }
      }
      if(entry.delta['Auspice: agency'])
        entry.delta['Auspice: agency'] = entry.delta?.['Auspice: agency']?.map(agencyObj => agencyObj?.name || '');
      if(entry.delta['Auspice: personId'])
        entry.delta['Auspice: personId'] = entry.delta?.['Auspice: personId']?.map(personObj => personObj?.name || '');
      return entry;
    });
  };

  const changeCastingHistory = (history, _id) => {
    if(!history) return [];
    return history
      .filter(entry => !entry.recordCreated)
      .map(entry => {
        if(!entry.delta){
          entry.delta = {
            created: [_id]
          };
        }
        for (const [key, value] of Object.entries(entry.delta)) {
          if(key !== 'userId'){
            entry.delta[`Casting: ${key}`] = value;
            delete entry.delta[key];
          }
        }
        if(entry.delta["Casting: priority"])
          entry.delta['Casting: priority'] = entry.delta?.['Casting: priority']?.filter(agencyObj => agencyObj !== null).map(agencyObj => agencyObj.toString());
        if(entry.delta["Casting: ageMax"])
          entry.delta['Casting: ageMax'] = entry.delta?.['Casting: ageMax']?.filter(agencyObj => agencyObj !== null).map(agencyObj => agencyObj.toString());
        if(entry.delta["Casting: ageMin"])
          entry.delta['Casting: ageMin'] = entry.delta?.['Casting: ageMin']?.filter(agencyObj => agencyObj !== null).map(agencyObj => agencyObj.toString());
        if(entry.delta['Casting: last_updated_date'])
          delete entry.delta['Casting: last_updated_date'];
        if(entry.delta['Casting: representedByAgency'])
          entry.delta['Casting: representedByAgency'] = entry.delta?.['Casting: representedByAgency']?.map(agencyObj => agencyObj?.name || '');
        if(entry.delta['Casting: personId']){
          if(!Array.isArray(entry.delta['Casting: personId']))
            entry.delta['Casting: personId'] = entry.delta['Casting: personId'].name;
          else
            entry.delta['Casting: personId'] = entry.delta?.['Casting: personId']?.map(personObj => personObj?.name || '');
        }
        return entry;
      });
  };

  useEffect(() => {
    dispatch(listHistoryProject(projects.project._id));
    dispatch(projectLoadRoles(projects.project._id)).then( ({ body }) => setRoles(body));
    dispatch(getProjectCastings(projects.project._id)).then( ({ body }) => setCastings(body));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHistory([
        ...(projects.history || []),
        ...(roles?.map(role => changeAuspiceHistory(role.history, role._id)) || []),
        ...(castings?.map(casting => changeCastingHistory(casting.history, casting._id)) || [])
      ]
      .flat()
      .sort((a,b) => b.timestamp - a.timestamp)
    );
  }, [projects.history, roles, castings]);

  const [focused, setFocused] = useState({ focused: 0 });
  return (
    <div>
      <table id={'scriptTable'} className={styles.table}>
        <thead>
          <tr className={styles.th}>
            <th className={styles.thText}>Date</th>
            <th className={styles.thText}>Field Name</th>
            <th className={styles.thText}>Previous Value</th>
            <th className={styles.thText}>New Value</th>
            <th className={styles.thText}>Action</th>
            <th className={styles.thText}>User</th>
          </tr>
        </thead>
        <tbody>
          <RenderList history={history} setFocused={setFocused} focused={focused} />
        </tbody>
      </table>
    </div>
  );
};
const RenderList = ({ history }) => {
  let showTable = false;

  if (history !== null && history.length > 0) showTable = true;

  return showTable ? (
    <>
      {history.map((loopItem, index) => {
        const item = {
          _id: index,
          delta: loopItem.delta || { project: [[], [loopItem.fieldId]] },
          user: {
            name: `${loopItem.userId.first_name} ${loopItem.userId.last_name}` || 'Oasis',
            _id: loopItem.userId._id || undefined,
          },
          timestamp: loopItem.timestamp,
          recordDeleted: loopItem.recordDeleted,
        };
        return <History history={item} index={index} key={index} />;
      })}
    </>
  ) : (
    <tr />
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  addContainer: helpers.merge(sizes.container, {
    paddingTop: 25,
    background: 'white',
    border: '1px black solid',
  }),
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  starActiveIcon: icons.starActive,
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  loading: {
    textAlign: 'center',
    padding: 10,
  },
  menu: helpers.merge(
    {
      display: 'flex',
      marginBottom: 20,
    },
    sizes.container
  ),
});

const withState = connect(state => {
  return state;
});

export default withState(withStyles(Activity));
