import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { AgGridReact as AgGrid } from 'ag-grid-react';
import ActionButtons from './../../../../../../components/action-buttons/action-buttons';
import { ProjectBadges } from './../../../../../../components/filter-badges/filter-project-badges';
import SortableColumnHeader from '../../../../../../components/sortable-column-header/sortable-column-header';

const Grid = ({
  projects,
  filterModel,
  selectAll,
  selection,
  buttons,
  onFetchMore,
  resultCount,
  maxCount,
  onSortChanged,
  onSelectedChanged,
  onSelectedChangedAll,
  resetFilters,
  filterBadges,
  sortColumn,
  sortDirection,
}) => {
  const gridRef = useRef(null);

  const refreshGrid = useCallback(() => {
    gridRef.current.api.refreshHeader();
  }, []);

  const showLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isSelected, setIsSelected] = useState(null);

  const getRowId = useMemo(() => {
    return params => params.data.id;
  }, []);

  const columDefinations = (currentSelectedCol, direction) => {
    return [
      {
        headerName: 'Name',
        field: 'Project.Name',
        width: 250,
        wrapText: true,
        autoHeight: true,
        cellClass: params => {
          if (!params.data.Active) return 'strikeout';
        },
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Type',
        field: 'Type',
        width: 150,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'State',
        field: 'State',
        width: 150,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Classification',
        field: 'Classification',
        width: 180,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Genre',
        field: 'Genre',
        width: 180,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Capacity',
        field: 'Capacity',
        width: 150,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Start',
        field: 'Start',
        width: 140,
        wrapText: true,
        autoHeight: true,
        sortable: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Updated',
        field: 'Updated',
        width: 170,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Created',
        field: 'Created',
        width: 180,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Production',
        field: 'Production',
        width: 180,
        sortable: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Network',
        field: 'Network',
        width: 150,
        sortable: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Studio',
        field: 'Studio',
        width: 150,
        sortable: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Director',
        field: 'Director',
        width: 150,
        sortable: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Talent',
        field: 'Talent',
        width: 150,
        sortable: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Literary',
        field: 'Literary',
        width: 150,
        sortable: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Promoters',
        field: 'Promoters',
        cellRenderer: params => {
          return (
            <div className="d-flex flex-column">
              {params?.data?.Promoters?.length > 0 && (
                <React.Fragment>
                  {params?.data?.Promoters?.map((item, index, arr) => (
                    <div key={index} className="pl-1">
                      {item + (index !== arr.length - 1 ? ',' : '')}
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          );
        },
        width: 180,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Agent',
        field: 'Agent',
        cellRenderer: params => {
          return (
            <div className="d-flex flex-column">
              {params?.data?.Agent?.length > 0 && (
                <React.Fragment>
                  {params?.data?.Agent?.map((item, index, arr) => (
                    <div key={index} className="pl-1">
                      {item + (index !== arr.length - 1 ? ',' : '')}
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          );
        },
        width: 150,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
    ];
  };

  const [columnDefs, setColumDefs] = useState(columDefinations(isSelected, sortDirection));

  useEffect(() => {
    setColumDefs(columDefinations(isSelected, sortDirection));
  }, [isSelected, sortDirection]); // eslint-disable-line react-hooks/exhaustive-deps

  const sentinelRef = useRef(null);
  const [rowData, setRowData] = useState(null);
  const feature = 'Feature';
  const television = 'Television';
  const unscripted = 'Unscripted';
  const theatre = 'Theatre';
  const fairsAndFestival = 'Fairs and Festivals';
  const display = 'display';
  const hide = 'hide';

  useEffect(() => {
    const data = formatData(projects);
    setRowData(data);
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rowData?.length) {
      if (selectAll && selection.size === 0) {
        gridRef.current.api.selectAll();
      }
      if (selectAll && selection.size !== 0) {
        const selectedRows = Array.from(selection);
        gridRef.current.api.forEachNode(node => {
          if (!selectedRows.includes(node.data.Project.id)) node.setSelected(true);
        });
      }
    }
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterModel) {
      columnVisibility();
    }
  }, [filterModel.types, filterModel?.projectSearchDateType, filterModel.sort]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridRef?.current) {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }
  }, [gridRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const columnVisibility = useCallback(async () => {
    await gridRef.current;
    if (!filterModel.types?.length || filterModel.types.length === 0) {
      getDefaultColumns();
      return;
    }
    if (filterModel.types?.length > 0) {
      const list = filterModel.types;
      const isFeature = list.some(item => item === feature);
      const isTelevision = list.some(item => item === television);
      const isUnscripted = list.some(item => item === unscripted);
      const isTheatre = list.some(item => item === theatre);
      const isFairsAndFestival = list.some(item => item === fairsAndFestival);
      isFeature && updateColumnVisibility(hide, fairsFilters.hide);
      isTelevision && updateColumnVisibility(hide, televisionFilters.hide);
      isUnscripted && updateColumnVisibility(hide, scriptedFilters.hide);
      isTheatre && updateColumnVisibility(hide, theatreFilters.hide);
      isFairsAndFestival && updateColumnVisibility(hide, fairsAndFestivalFilters.hide);
      isFairsAndFestival && updateColumnVisibility(display, fairsAndFestivalFilters.display);
      if (
        (isFeature && (isTelevision || isUnscripted || isTheatre || isFairsAndFestival)) ||
        (isTelevision && (isFeature || isUnscripted || isTheatre || isFairsAndFestival)) ||
        (isUnscripted && (isFeature || isTelevision || isTheatre || isFairsAndFestival)) ||
        (isTheatre && (isFeature || isTelevision || isUnscripted || isFairsAndFestival)) ||
        (isFairsAndFestival && (isFeature || isTelevision || isUnscripted || isTheatre))
      ) {
        updateColumnVisibility(display, defaultColumnsDisplay);
        updateColumnVisibility(hide, defaultColumnsHidden);
      }
    }
    applyDateFilter();
    gridRef.current.api.sizeColumnsToFit();
  }, [filterModel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && projects && projects.length < maxCount && projects.length < resultCount) {
            onFetchMore();
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [onFetchMore, resultCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const applyDateFilter = () => {
    const columns = gridRef.current.columnApi.getAllDisplayedColumns();
    const activeColumns = columns.map(item => item.colDef.headerName);
    if (filterModel.projectSearchDateType) {
      const columnsList = dateFilterType(filterModel.projectSearchDateType);
      const columnsToDisplay = activeColumns.filter(item => !columnsList.includes(item));
      const columnsToHide = activeColumns.filter(item => columnsList.includes(item));
      gridRef.current.columnApi.setColumnsVisible(columnsToDisplay, true);
      gridRef.current.columnApi.setColumnsVisible(columnsToHide, false);
    } else {
      gridRef.current.columnApi.setColumnsVisible([...activeColumns, ...dateFilterDefaultList], true);
    }
  };

  const getDefaultColumns = () => {
    updateColumnVisibility(display, defaultColumnsDisplay);
    updateColumnVisibility(hide, defaultColumnsHidden);
  };

  const dateFilterType = filter => {
    if (filter === 'Last Updated') return ['Start', 'Created'];
    if (filter === 'Created') return ['Updated', 'Start'];
    if (filter === 'Start Date') return ['Updated', 'Created'];
  };

  const onRowSelect = event => {
    const id = event.data.Project.id;
    onSelectedChanged(id);
  };

  const onSelectAll = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows();
    const totalRows = gridRef?.current?.api.getDisplayedRowCount();
    const isAll = selectedRows?.length === totalRows;
    const isNone = selectedRows?.length === 0;
    isAll ? onSelectedChangedAll(isAll) : isNone && onSelectedChangedAll(isAll);
  };

  const onCellClicked = event => {
    const projectId = event?.data?.Project?.id;
    if (projectId) window.open(`/project/${projectId}`, '_blank', 'noopener,noreferrer');
  };

  const updateColumnVisibility = (type, columns) => {
    const visibilityType = 'display';
    const display = visibilityType === type ? true : false;
    !display
      ? gridRef.current.columnApi.setColumnsVisible(columns, false)
      : gridRef.current.columnApi.setColumnsVisible(columns, true);
  };

  const onGridReady = e => {
    e.api.sizeColumnsToFit();
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center flex-wrap">
        <div className="col-12">
          <ActionButtons buttons={buttons} />
          <ProjectBadges resetFilters={resetFilters} badges={filterBadges} />
        </div>
        <div className="ag-theme-alpine w-100">
          <AgGrid
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            domLayout={'autoHeight'}
            gridOptions={{
              suppressClickEdit: true,
              colResizeDefault: 'shift',
              suppressScrollOnNewData: true,
              suppressRowTransform: true,
              animateRows: false,
              accentedSort: false,
              cacheBlockSize: 1,
              maxBlocksInCache: 1,
              onNewColumnsLoaded: e => {
                e.api.sizeColumnsToFit();
              },
            }}
            rowBuffer={rowData ? rowData?.length : 100}
            debounceVerticalScrollbar={true}
            animateRows={false}
            suppressRowClickSelection={true}
            rowSelection="multiple"
            icons={icons}
            onRowSelected={event => onRowSelect(event)}
            onSelectionChanged={() => onSelectAll()}
            onCellClicked={event => onCellClicked(event)}
            onGridReady={onGridReady}
            getRowId={getRowId}
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
            }
          />
          <div className="ais-InfiniteHits-sentinel" ref={sentinelRef} aria-hidden="true" />
          {projects?.length && PageCount(projects?.length, resultCount)}
          {projects?.length >= maxCount && (
            <div className="py-4" style={{ textAlign: 'center' }}>
              <p className="c-dialog">
                Showing the first {maxCount} results of {resultCount} matching your search.
              </p>
              <br />
              <p className="c-dialog">Please narrow your search to show all results.</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Grid);

const formatData = projects => {
  const data = projects?.map(hit => ({
    id: hit?.objectID,
    Project: { Name: hit?.name, id: hit?.objectID },
    Type: hit?.type,
    Updated: hit?.last_updated_date ? new Date(hit.last_updated_date).toLocaleDateString('en-US') : '',
    Created: hit?.created_date ? new Date(hit.created_date).toLocaleDateString('en-US') : '',
    Start: hit?.opened_date ? new Date(hit.opened_date).toLocaleDateString('en-US') : '',
    Studio: hit?.studios && hit?.studios[0] ? hit?.studios[0].name : '',
    Production: hit?.productionCompanies && hit?.productionCompanies[0] ? hit?.productionCompanies[0].name : '',
    Network: hit?.networks && hit?.networks[0] ? hit?.networks[0].name : '',
    Director: hit?.director,
    Talent: hit?.talentAgents && hit?.talentAgents[0] ? hit?.talentAgents[0].name : '',
    Literary: hit?.literaryAgents && hit?.literaryAgents[0] ? hit?.literaryAgents[0].name : '',
    Active: hit?.active,
    State: hit?.locationState,
    Classification: hit?.classification,
    Genre: hit?.genre,
    Capacity: hit?.capacity,
    Promoters: hit?.promoters?.map(item => item?.name),
    Agent: hit?.pointAgents?.map(item => item?.name),
  }));
  return data;
};

const icons = {
  sortAscending:
    '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.8em" width="0.8em" xmlns="http://www.w3.org/2000/svg"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>',
  sortDescending:
    '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.8em" width="0.8em" xmlns="http://www.w3.org/2000/svg"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>',
  sortUnSort:
    '<div class="d-flex flex-column p-0"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.6em" width="0.7em" xmlns="http://www.w3.org/2000/svg"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.6em" width="0.7em" xmlns="http://www.w3.org/2000/svg"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg></div>',
};

const defaultColumnsDisplay = [
  'Name',
  'Type',
  'Start',
  'Updated',
  'Created',
  'Production',
  'Studio',
  'Network',
  'Director',
  'Talent',
  'Literary',
];

const defaultColumnsHidden = ['State', 'Classification', 'Genre', 'Capacity', 'Promoters', 'Agent'];

const fairsFilters = {
  hide: ['Network', 'Type'],
};

const televisionFilters = {
  hide: ['Type'],
};

const scriptedFilters = {
  hide: ['Director', 'Type'],
};

const theatreFilters = {
  hide: ['Network', 'Studio', 'Director', 'Type'],
};

const fairsAndFestivalFilters = {
  display: ['State', 'Classification', 'Genre', 'Capacity', 'Promoters', 'Agent'],
  hide: ['Production', 'Studio', 'Network', 'Network', 'Director', 'Talent', 'Literary', 'Type'],
};
const ColumnHeader = col => {
  const columnName = col.column.colId === 'Project.Name' ? 'Name' : col.column.colId;
  let currentDirection = col.sortColumn === columnName ? col.sortDirection : null;

  const handleOnClick = direction => {
    col.showLoading();
    col.updateSelectedCol(columnName);
    currentDirection = direction;
    col.onSortChanged(columnName, direction);
    col.refreshGrid();
  };

  return <SortableColumnHeader text={col.displayName} direction={currentDirection} onClick={handleOnClick} />;
};

const dateFilterDefaultList = ['Start', 'Created', 'Updated'];

const PageCount = (currentProjectsAmount, totalProjects) => {
  return (
    <div className="float-right py-2">
      <span className="c-dialog-count">
        {currentProjectsAmount} / {totalProjects}
      </span>
    </div>
  );
};
