import React, { Component } from 'react';
import { styled } from 'react-free-style';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { Input } from '@united-talent-agency/julius-frontend-components';
import PrintOptions from '../print-options/print-options';

class GridManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title || 'Untitled',
      printOptions: props.printOptions || {
        executives: true,
        includeLiteraryExecs: true,
        includeTalentExecs: true,
        staff: true,
        includeStaffAgencies: false,
        cast: true,
        index: true,
        talentNotes: false,
        literaryNotes: false,
        indieNotes: false,
        includeInternalLiteraryNotes: false,
        budget: true,
        internalHeader: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        agents: true,
        talentAgents: true,
        literaryAgents: true,
        indieAgents: true,
        budgetNotes: false,
      },
    };
  }

  save() {
    this.props.createGrid(this.state.title, this.state.printOptions);
    this.props.collapseEvent();
  }

  // eslint-disable-next-line no-undef
  onChange = printOptions => {
    this.setState({ printOptions });
  };

  render() {
    const { collapseEvent, styles, isClone } = this.props;
    const self = this;

    return (
      <div className={styles.container}>
        <div className={`ml-1 row`}>
          <h5 className="mx-0 mb-2 mt-0 p-0">{isClone ? 'Clone this Grid' : 'Save as Grid'}</h5>
          <div style={{ cursor: 'pointer' }} className="ml-auto mr-3" onClick={collapseEvent}>
            <i className={'fa fa-fw fa-window-close'} aria-hidden="true" />
          </div>
        </div>

        <ListGroup>
          <ListGroupItem style={{ paddingLeft: 0, marginBottom: '5px' }}>
            <div className={`row ml-3`}>
              <div style={{ width: '260px' }}>
                <Input
                  title="New Grid"
                  value={this.state.title}
                  onChange={value => {
                    self.setState({ title: value });
                  }}
                />
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>

        <PrintOptions onSelectionsChanged={this.onChange} options={this.props.printOptions} />

        <div align="center" className="mt-4">
          <div
            onClick={collapseEvent}
            style={{
              fontSize: 12,
              padding: '5px',
              marginLeft: '5px',
              textTransform: 'uppercase',
              cursor: 'pointer',
              display: 'inline-block',
            }}
          >
            <i className="fa fa-w fa-ban" style={{ marginRight: '5px' }} />
            <span>Cancel</span>
          </div>
          <div
            onClick={this.save.bind(this)}
            style={{
              fontSize: 12,
              padding: '5px',
              marginLeft: '5px',
              textTransform: 'uppercase',
              cursor: 'pointer',
              flex: 0.5,
              display: 'inline-block',
            }}
          >
            <i className="fa fa-w fa-print" style={{ marginRight: '5px' }} />
            <span>Save</span>
          </div>
        </div>
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 5,
  },
});

export default withStyles(GridManager);
