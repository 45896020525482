import React, { Component, Fragment } from 'react';
import { helpers, styled } from 'react-free-style';

import { createProject, projectSearchDuplicateProjects } from '@united-talent-agency/julius-frontend-store';
import { elements } from '@united-talent-agency/julius-frontend-components';

import { icons } from '../../../styles/icons';
import ProjectForm from './project-form';

class ProjectFormMenuComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddOpen: false,
    };
  }

  toggleAdd() {
    this.setState({ isAddOpen: !this.state.isAddOpen });
  }

  render() {
    const { styles } = this.props;
    const { isAddOpen } = this.state;
    return (
      <Fragment>
        {isAddOpen ? (
          <Fragment>
            <button
              className={styles.button}
              onClick={() => {
                this.toggleAdd();
              }}
            >
              <span className={styles.buttonText}>
                <i className={styles.cancelIcon} /> Cancel
              </span>
            </button>
            <div className={styles.addProjectFormContainer}>
              <ProjectForm
                createProject={async project => this.props.dispatch(createProject(project))}
                searchDupes={async ({ name, type }) =>
                  this.props.dispatch(projectSearchDuplicateProjects({ name, type }))
                }
                onDone={projectId => {
                  this.setState({ isAddOpen: false });
                  if (projectId) {
                    window.open(`/project/${projectId}`, '_blank');
                  }
                }}
              />
            </div>
          </Fragment>
        ) : (
          <button className={styles.button} onClick={() => this.toggleAdd()}>
            <span className={styles.buttonText} />
            <i className={styles.addIcon} /> Add Project
          </button>
        )}
      </Fragment>
    );
  }
}

const withStyles = styled({
  addProjectFormContainer: {
    width: '100%',
    maxWidth: '800px',
    marginLeft: 10,
    background: 'white',
    border: '1px black solid',
  },
  button: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    marginLeft: 30,
  }),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cancelIcon: icons.smallCrossBlack,
  addIcon: icons.plus,
});

export default withStyles(ProjectFormMenuComponent);
