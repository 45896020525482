import { get } from './index.js';

/**
 * Makes an API call to the GET /person resource
 *    searchPeopleByProjectRole({searchText:"asdf", projectRole:"executive"}) =>
 *      /person?$search={searchText}&projectRole={projectRole}}
 *
 * @param searchText: The text to search for in person
 * @param projectRole:  The role the person provides:
 *                  - projects.staff
 *                  - projects.talent
 *                  - projects.agent
 *                  - projects.literaryAgent
 *                  - projects.indieAgent
 *                  - projects.pointAgent
 *                  - projects.executive
 *                  - projects.contract
 *                  - projects.contractAdministrator
 *
 * @returns A list of groups from the response payload (no meta result yet)
 **/
export const searchPeopleByProjectRole = ({ searchText, projectRole, _get = get }) => {
  const query = [];
  if (searchText) {
    query.push({ $search: `${searchText}` });
  }
  let execPlan;
  if (projectRole) {
    // use this query to include filter of projectRole when searching
    query.push({ projectRole: projectRole });
  } 
  execPlan = _get(`/personsearch/projectrole`, query)
    .then(response => {
      const { data } = (response || {}).body || {};
      return data;
    })
    .catch(error => {
      console.error('Handled API Error from /person', error);
      return Promise.reject(error);
    });
  return execPlan;
};
