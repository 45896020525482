import React, { useState, useReducer } from 'react';
import { Spinner } from '@united-talent-agency/components';

import SortableColumnHeader from '../../../../../components/sortable-column-header/sortable-column-header';
import { getRepresentedByAgencies } from '../../../../../dao/person-agencies';
import { Actions } from './actions';
import { PositionInput, StatusInput, PersonInput, TextInput, TalentAgencyInput } from './inputs';
import { sort } from './sorter';

const AuspicesEntry = ({ role, loadRoles, project, updateRole, projectDeleteRole, existingRoles = [] }) => {
  const [updates, setUpdates] = useState({});
  const [personAgencies, setPersonAgencies] = useState({});

  const archiveAction = role => {
    return () => {
      role.active = !role.active;
      updateRole(role._id, role).then(() => {
        loadRoles();
      });
    };
  };

  const deleteAction = role => {
    return () => {
      projectDeleteRole(role._id).then(() => {
        loadRoles();
      });
    };
  };

  const updateAuspice = (id, field, value) => {
    if (!updates[id]) {
      updates[id] = {};
    }
    if (!updates[id][field]) {
      updates[id][field] = {};
    }
    updates[id][field].value = value;
    setUpdates(Object.assign({}, updates));
  };

  const saveAuspiceFields = () => {
    for (let id in updates) {
      const updatesToSave = {};
      for (let field in updates[id]) {
        if (field === 'errors') {
          continue;
        }
        if (!updates[id].errors) {
          updatesToSave[field] = updates[id][field].value;
        }
      }
      // needed so that, when the update is propagated, we are _guaranteed_ to have all the required info
      // we need both agency and personId in case either of them changes
      //!updatesToSave['agency'] && Object.assign(updatesToSave, { agency: role.agency });
      !updatesToSave['personId'] && Object.assign(updatesToSave, { personId: role.personId });
      return updateRole(id, updatesToSave)
        .then(response => {
          for (let updatedField in updatesToSave) {
            role[updatedField] = response.body[updatedField];
            delete updates[id][updatedField];
          }
          return setUpdates(Object.assign({}, updates));
        })
        .then(() => loadRoles())
        .catch(err => {
          console.error(err);
        });
    }
  };

  const isSaving = (updates, id, field) => {
    return updates[id] && updates[id][field] && updates[id][field].saving;
  };
  const getValue = (updates, role, field) => {
    return updates[role._id] && updates[role._id][field] ? updates[role._id][field].value : role[field];
  };

  const clearError = (updates, role, field, error) => {
    if (updates[role._id] && updates[role._id][field] && updates[role._id][field].error) {
      delete updates[role._id]['status'].errors[error];
    }
  };

  const setError = (updates, role, field, error) => {
    updates[role._id][field].errors = Object.assign(error, updates[role._id][field].errors);
  };

  const getError = (updates, role, field) => {
    return updates[role._id] && updates[role._id][field] && updates[role._id][field].errors;
  };

  const getErrors = (updates, role) => {
    const errors = new Set();
    if (updates[role._id]) {
      for (let field in updates[role._id]) {
        if (updates[role._id][field].errors) {
          for (let error in updates[role._id][field].errors) {
            errors.add(updates[role._id][field].errors[error]);
          }
        }
      }
    }
    return Array.from(errors);
  };

  const validateStatus = (updates, role, field) => {
    const status = getValue(updates, role, 'status');
    const personId = getValue(updates, role, 'personId');
    const isValid = status === 'Open' ? !personId : !!personId;
    if (!isValid) {
      const errorMsg = status === 'Open' ? 'Cannot assign person to open position' : 'Person must be assigned';
      setError(updates, role, field, { status: errorMsg });
    } else {
      clearError(updates, role, 'status', 'status');
      clearError(updates, role, 'personId', 'status');
    }
    return isValid;
  };

  const validateDuplicate = (updates, role, field) => {
    const type = getValue(updates, role, 'type');
    const personId = getValue(updates, role, 'personId');
    const existingRole = existingRoles.find(r => {
      return r._id !== role._id && r.type === type && r.personId && personId && r.personId.name === personId.name;
    });
    const isValid = !existingRole;
    if (!isValid) {
      setError(updates, role, field, { type: `Duplicate Auspice${existingRole.active ? '' : ' (Archived)'}` });
    } else {
      clearError(updates, role, 'type', 'type');
      clearError(updates, role, 'personId', 'type');
    }
    return isValid;
  };

  const validateAndSave = (updates, role, field) => {
    if (updates[role._id] && updates[role._id][field]) {
      const validType = validateDuplicate(updates, role, field);
      const validStatus = validateStatus(updates, role, field);
      const isValid = validType && validStatus;
      if (isValid) {
        updates[role._id][field].saving = true;
        setUpdates(Object.assign({}, updates));
        saveAuspiceFields();
      } else {
        setUpdates(Object.assign({}, updates));
      }
    }
  };

  const getSelectStyles = (baseStyles, updates, role, field, flex) => {
    const retVal = Object.assign({}, baseStyles);
    if (flex) {
      retVal.flex = flex;
    }
    if (getError(updates, role, field)) {
      retVal.controlStyles = Object.assign(Object.assign({}, retVal.controlStyles), { border: '1px solid red' });
    }
    return retVal;
  };

  const positionsEntryClass = role.active ? 'auspices-table-entry' : 'auspices-table-archived-entry';
  const projectType = project.type;
  const backgroundColor = role.active ? 'white' : '#F5F5F5';
  const color = role.active ? 'black' : '#bcbbbc';
  const textInputStyles = {
    flex: '0.18',
    border: '0px',
    outlineWidth: '2px',
    fontSize: '12px',
    paddingTop: '12px',
    fontWeight: 300,
    color,
    backgroundColor,
  };
  const selectInputStyles = {
    controlStyles: {
      minHeight: null,
      border: '0px',
      fontSize: '12px',
    },
    indicatorsContainerStyles: { display: 'none' },
    placeholderStyles: { top: '40%', fontSize: '12px' },
    menuListStyles: { fontSize: '12px' },
    flex: '0.21',
  };
  const errors = getErrors(updates, role);

  return (
    <React.Fragment>
      <div className="auspices-table-entry-container" style={{ backgroundColor, color }}>
        <PositionInput
          onChange={({ value }) => updateAuspice(role._id, 'type', value)}
          type={projectType}
          value={getValue(updates, role, 'type')}
          onBlur={() => validateAndSave(updates, role, 'type')}
          className={positionsEntryClass}
          {...getSelectStyles(selectInputStyles, updates, role, 'type')}
        />
        {isSaving(updates, role._id, 'type') && (
          <div style={{ position: 'relative', left: -30, width: 0, marginTop: 5 }}>
            <Spinner size={20} />
          </div>
        )}
        <StatusInput
          onChange={({ value }) => {
            updateAuspice(role._id, 'status', value);
            if (value === 'Open') {
              updateAuspice(role._id, 'personId', null);
              updateAuspice(role._id, 'agency', null);
            }
          }}
          type={projectType}
          value={getValue(updates, role, 'status')}
          onBlur={() => validateAndSave(updates, role, 'status')}
          className={positionsEntryClass}
          {...getSelectStyles(selectInputStyles, updates, role, 'status', 0.14)}
        />
        {isSaving(updates, role._id, 'status') && (
          <div style={{ position: 'relative', left: -30, width: 0, marginTop: 5 }}>
            <Spinner size={20} />
          </div>
        )}
        <PersonInput
          onChange={selected => {
            const update = selected.__isNew__
              ? { _id: selected.value, name: selected.value }
              : { _id: selected.value._id, name: selected.value.name };
            updateAuspice(role._id, 'personId', update);
            getRepresentedByAgencies(update).then(personAgencies => {
              if (personAgencies.primary) {
                updateAuspice(role._id, 'agency', personAgencies.primary);
              }
              personAgencies[role._id] = personAgencies.agencies;
              setPersonAgencies(Object.assign({}, personAgencies));
            });
          }}
          hidePlaceholder
          type={projectType}
          value={{
            ...getValue(updates, role, 'personId'),
            emphasizeRolePerson: getValue(updates, role, 'emphasizeRolePerson'),
          }}
          onBlur={() => validateAndSave(updates, role, 'personId')}
          linkClassName={role.active ? 'auspices-table-profile-link' : 'auspices-table-archived-profile-link'}
          {...getSelectStyles(selectInputStyles, updates, role, 'personId')}
        />
        {isSaving(updates, role._id, 'personId') && (
          <div style={{ position: 'relative', left: -30, width: 0, marginTop: 5 }}>
            <Spinner size={20} />
          </div>
        )}
        <TalentAgencyInput
          hidePlaceholder
          value={getValue(updates, role, 'agency')}
          defaultAgencies={personAgencies[role._id]}
          className={positionsEntryClass}
          onChange={change => {
            if (!change) {
              updateAuspice(role._id, 'agency', null);
              return;
            }
            const { value = '' } = change;
            const update =
              typeof value === 'string' ? { _id: value, name: value } : { _id: value._id, name: value.name };
            updateAuspice(role._id, 'agency', update);
          }}
          onBlur={() => validateAndSave(updates, role, 'agency')}
          linkClassName={role.active ? 'auspices-table-profile-link' : 'auspices-table-archived-profile-link'}
          {...getSelectStyles(selectInputStyles, updates, role, 'agency')}
        />
        {isSaving(updates, role._id, 'agency') && (
          <div style={{ position: 'relative', left: -30, width: 0, marginTop: 5 }}>
            <Spinner size={20} />
          </div>
        )}
        <TextInput
          value={getValue(updates, role, 'note')}
          className={positionsEntryClass}
          onChange={value => updateAuspice(role._id, 'note', value)}
          onBlur={() => validateAndSave(updates, role, 'note')}
          styles={textInputStyles}
        />
        {isSaving(updates, role._id, 'note') && (
          <div style={{ position: 'relative', left: -30, width: 0, marginTop: 5 }}>
            <Spinner size={20} />
          </div>
        )}
        <Actions archiveAction={archiveAction(role)} deleteAction={deleteAction(role)} isActive={role.active} />
      </div>
      {errors.length > 0 && (
        <div className="text-danger auspices-table-inline-duplicate-error">{errors.join('. ')}</div>
      )}
    </React.Fragment>
  );
};

const SortableTableHeader = ({ header, sortBy, changeSort, customStyle = {} }) => (
  <div className="sortable-auspices-table-header" style={customStyle}>
    <SortableColumnHeader
      text={header}
      direction={sortBy.category === header ? (sortBy.direction === 'ASC' ? 1 : -1) : 0}
      onClick={changeSort}
    />
  </div>
);

const UnSortableTableHeader = ({ header, customStyle = {} }) => (
  <div className="unsortable-auspices-table-header" style={{ ...customStyle }}>
    <span>{header}</span>
  </div>
);

export const AuspicesTable = ({ roles, loadRoles, project, updateRole, projectDeleteRole, allRoles }) => {
  const [sortBy, setSortBy] = useReducer((state, newState) => ({ ...state, ...newState }), {
    direction: 'ASC',
    category: 'Auspice',
  });
  const changeSort = newSortCategory => {
    if (newSortCategory !== sortBy.category) {
      sortBy.category = newSortCategory;
    } else {
      sortBy.direction = sortBy.direction === 'ASC' ? 'DSC' : 'ASC';
    }
    setSortBy(sortBy);
  };
  sort(sortBy, roles);
  return (
    <React.Fragment>
      <div className="auspices-table-header-container">
        <SortableTableHeader header="Auspice" sortBy={sortBy} changeSort={() => changeSort('Auspice')} />
        <SortableTableHeader
          header="Status"
          sortBy={sortBy}
          changeSort={() => changeSort('Status')}
          customStyle={{ flex: 0.19 }}
        />
        <SortableTableHeader
          header="Person"
          sortBy={sortBy}
          changeSort={() => changeSort('Person')}
          customStyle={{ flex: 0.28 }}
        />
        <SortableTableHeader header="Agency" sortBy={sortBy} changeSort={() => changeSort('Agency')} />
        <UnSortableTableHeader header="Notes" customStyle={{ paddingRight: '20px' }} />
        <UnSortableTableHeader header="Actions" customStyle={{ textAlign: 'end', paddingRight: '15px' }} />
      </div>
      <div className="auspices-table">
        {roles &&
          roles.map(role => {
            return (
              <AuspicesEntry
                role={role}
                key={role._id}
                loadRoles={loadRoles}
                project={project}
                updateRole={updateRole}
                projectDeleteRole={projectDeleteRole}
                existingRoles={allRoles}
              />
            );
          })}
      </div>
    </React.Fragment>
  );
};
