import React, { Component } from 'react';
import classnames from 'classnames';
import { styled } from 'react-free-style';

import { Dropdown, Input } from '@united-talent-agency/julius-frontend-components';

const ESCAPE_KEY = 27;
const DOWN_KEY = 40;

class DropdownFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ignoreBlur: false,
      focused: false,
    };
  }

  onKeyDown(event) {
    const { results, value, onCancel, onChange } = this.props;
    const { keyCode } = event;

    if (keyCode === ESCAPE_KEY) onCancel && onCancel();
    if (keyCode === DOWN_KEY && !results) onChange(value);
  }

  onFocus(focused = true) {
    const { onFocusChanged = () => {} } = this.props;
    this.setState({ focused: true });
    onFocusChanged(focused);
  }

  render() {
    const { className, focus, onCancel, onSelectStatus, styles, title, results, selectedResults } = this.props;

    let items = [],
      selectedItems = [];

    if (results) {
      items = results.map(item => {
        return {
          key: item._id,
          className: styles.item,
          onClick: () => {
            onSelectStatus(item._id);
          },
          content: (
            <div>
              <span className={styles.selectText}>{item.title}</span>
            </div>
          ),
        };
      });
    }

    if (selectedResults) {
      selectedItems = selectedResults.map(selectedItem => {
        return {
          key: selectedItem.property,
          className: styles.selectedItem,
          content: (
            <div>
              <span className={styles.selectedItem}>{selectedItem.label}</span>
            </div>
          ),
        };
      });
    }

    return (
      <div className={classnames(styles.container, className)}>
        <Input
          title={title || 'Status'}
          focus={focus}
          onFocus={() => this.onFocus()}
          onKeyDown={e => this.onKeyDown(e)}
          onBlurClear
          onBlur={() => {
            if (!this.state.ignoreBlur) onCancel && onCancel();
            this.onFocus(false);
            this.setState({ ignoreBlur: false, focused: false });
          }}
        />
        {this.state.focused && <Dropdown items={items} selectedItems={selectedItems} />}
      </div>
    );
  }
}
const withStyles = styled({
  container: {
    position: 'relative',
  },
  selectText: {
    fontSize: 13,
    fontWeight: 300,
    lineHeight: 1,
    padding: 10,
  },
  selectedItem: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,
    padding: 10,
  },
});

export default withStyles(DropdownFilter);
