import React from 'react';

const CountLabel = props => {
  const { count = 0, label, style, className } = props;
  const suffix = label && (label.endsWith('s') || label.endsWith('x') ? 'es' : 's');
  const displayLabel = label && pluralize(count, label, suffix);
  return (
    <div style={style} className={className}>
      {groupDigits(count)} {displayLabel}
    </div>
  );
};
const groupDigits = count => {
  return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const pluralize = (count, noun, suffix) => {
  return `${noun}${count !== 1 ? suffix : ''}`;
};

export default CountLabel;
