import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { AgGridReact as AgGrid } from 'ag-grid-react';
import { Spinner } from '@united-talent-agency/components';
import SortableColumnHeader from '../../../../../../components/sortable-column-header/sortable-column-header';

const Grid = ({
  projects,
  selectAll,
  selection,
  onSelectedChangedAll,
  onSelectedChanged,
  sortDirection,
  sortColumn,
  onSortChanged,
  getNewProjects,
  totalProjects,
}) => {
  const columDefinations = (currentSelectedCol, direction) => {
    return [
      {
        headerName: 'Name',
        field: 'Project.Name',
        flex: 1,
        sortable: true,
        wrapText: true,
        autoHeight: true,
        cellClass: params => {
          if (!params.data.Active) return 'strikeout';
        },
        headerCheckboxSelection: true,
        checkboxSelection: true,
        checkboxClass: 'checked:border-primary checked:bg-primary',
        showDisabledCheckboxes: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Type',
        field: 'Type',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Start',
        field: 'Start',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Updated',
        field: 'Updated',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Created',
        field: 'Created',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Production',
        field: 'Production',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Network',
        field: 'Network',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },

      {
        headerName: 'Studio',
        field: 'Studio',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Director',
        field: 'Director',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Talent',
        field: 'Talent',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Literary',
        field: 'Literary',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        headerComponentParams: {
          handleSortChange,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
    ];
  };
  const gridRef = useRef(null);
  const sentinelRef = useRef(null);
  const [pageSize] = useState(100);
  const [offset, setOffset] = useState(0);
  const [isSelected, setIsSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [columnDefs, setColumDefs] = useState(columDefinations(isSelected, sortDirection));

  const components = useMemo(() => {
    return {
      agColumnHeader: ColumnHeader,
    };
  }, []);

  const refreshGrid = useCallback(() => {
    gridRef.current.api.refreshHeader();
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const showLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return params => params.data.id;
  }, []);

  useEffect(() => {
    const data = formatData(projects);
    setRowData(data);
    isLoading && setIsLoading(false);
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setColumDefs(columDefinations(isSelected, sortDirection));
  }, [isSelected, sortDirection]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (offset >= pageSize) {
      getNewProjects(offset);
    }
    setIsLoading(false);
  }, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rowData?.length) {
      if (selectAll && selection.size === 0) {
        gridRef.current.api.selectAll();
      }
      if (selectAll && selection.size !== 0) {
        const selectedRows = Array.from(selection);
        gridRef.current.api.forEachNode(node => {
          if (!selectedRows.includes(node.data.Project.id)) node.setSelected(true);
        });
      }
    }
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(([entries]) => {
        const ratio = entries.intersectionRatio;
        if (ratio > 0) {
          const totalRows = gridRef?.current?.api.getDisplayedRowCount();
          if (totalRows > 0 && totalProjects > pageSize && totalRows < totalProjects) {
            setIsLoading(true);
            setOffset(oldOff => oldOff + pageSize);
          }
        }
      });
      if (sentinelRef.current) observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [sentinelRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCellClicked = event => {
    const projectId = event?.data?.Project?.id;
    if (projectId) window.open(`/project/${projectId}`, '_blank', 'noopener,noreferrer');
  };

  const onSelectAll = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows();
    const totalRows = gridRef?.current?.api.getDisplayedRowCount();
    const isAll = selectedRows?.length === totalRows;
    const isNone = selectedRows?.length === 0;
    isAll ? onSelectedChangedAll(isAll) : isNone && onSelectedChangedAll(isAll);
  };

  const onRowSelect = event => {
    const id = event.data.Project.id;
    onSelectedChanged(id);
  };

  const onGridReady = event => event.api.sizeColumnsToFit();

  const handleSortChange = (columnName, direction) => {
    onSortChanged(columnName, direction);
    setOffset(0);
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center flex-wrap w-100">
        <div className="ag-theme-alpine w-100 pb-5">
          <AgGrid
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            components={components}
            domLayout={'autoHeight'}
            gridOptions={{
              suppressClickEdit: true,
              colResizeDefault: 'shift',
              suppressScrollOnNewData: true,
              suppressRowTransform: true,
              animateRows: false,
              accentedSort: false,
              onNewColumnsLoaded: e => {
                e.api.sizeColumnsToFit();
              },
              onNewRowsLoaded: e => {
                e.api.sizeColumnsToFit();
              },
            }}
            rowBuffer={rowData ? rowData?.length : 100}
            debounceVerticalScrollbar={true}
            animateRows={false}
            suppressRowClickSelection={true}
            rowSelection="multiple"
            icons={icons}
            onGridReady={onGridReady}
            onRowSelected={event => onRowSelect(event)}
            onSelectionChanged={() => onSelectAll()}
            onCellClicked={event => onCellClicked(event)}
            getRowId={getRowId}
            overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">Loading....</span>'}
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
            }
          />
          <div className="ais-InfiniteHits-sentinel" ref={sentinelRef} aria-hidden="true" />
        </div>
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center flex-wrap w-100 pb-5">
            <Spinner size={25} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(Grid);

const formatData = projects => {
  const data = projects?.map(hit => ({
    id: hit?._id,
    Project: { Name: hit?.name, id: hit?._id },
    Type: hit?.type,
    Updated: hit?.last_updated_date ? new Date(hit.last_updated_date).toLocaleDateString('en-US') : '',
    Created: hit?.created_date ? new Date(hit.created_date).toLocaleDateString('en-US') : '',
    Start: hit?.opened_date ? new Date(hit.opened_date).toLocaleDateString('en-US') : '',
    Studio: hit?.studios?.map(item => item?.name),
    Production: hit?.productionCompanies?.map(item => item?.name),
    Network: hit?.networks?.map(item => item?.name),
    Director: hit?.executives?.directors?.map(item => item),
    Talent: hit?.talentAgents?.map(item => item?.name),
    Literary: hit?.literaryAgents?.map(item => item?.name),
    Active: hit?.active,
  }));
  return data;
};

const icons = {
  sortAscending:
    '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.8em" width="0.8em" xmlns="http://www.w3.org/2000/svg"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>',
  sortDescending:
    '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.8em" width="0.8em" xmlns="http://www.w3.org/2000/svg"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>',
  sortUnSort:
    '<div class="d-flex flex-column p-0"><svg stroke="currentColor" fill="#c0c0c0" stroke-width="0" viewBox="0 0 1024 1024" height="0.7em" width="0.7em" xmlns="http://www.w3.org/2000/svg"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg><svg stroke="currentColor" fill="#c0c0c0" stroke-width="0" viewBox="0 0 1024 1024" height="0.7em" width="0.7em" xmlns="http://www.w3.org/2000/svg"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg></div>',
};

const ColumnHeader = col => {
  const columnName = col.column.colId === 'Project.Name' ? 'Name' : col.column.colId;
  let currentDirection = col.sortColumn === columnName ? col.sortDirection : null;

  const handleOnClick = direction => {
    col.showLoading();
    col.updateSelectedCol(columnName);
    currentDirection = direction;
    col.handleSortChange(columnName, direction);
    col.refreshGrid();
  };

  return <SortableColumnHeader text={col.displayName} direction={currentDirection} onClick={handleOnClick} />;
};
