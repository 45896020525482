import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { notify } from 'react-notify-toast';
import { updateRole, createRole, projectLoadRoles, updateProject } from '@united-talent-agency/julius-frontend-store';

import { getGroups, groupRoles } from '../../../../../api/groups';
import LogLine from '../../../../../components/logline/logline';
import CastingsCard from './castings-card';
import ExecutiveControl from './executive-control';
import { ProfileCard } from '../../../../../components/profile-card/profile-card';
import ItemListCard from '../../../../../components/item-list-card/item-list-card.js';
import ReadByCard from '../../../../../components/readby-card/readby-card.js';
import InfoListCard from '../../../../../components/info-list-card/info-list-card.js';
import GroupByInfoListCard from '../../../../../components/info-list-card/group-by-info-list-card.js';
import { auspiceWeights } from '../../../../../components/info-list-card//auspice-weights';
import RoleListCard from '../../../../../components/role-list-card/role-list-card.js';
import RepAgreementsCard from '../../../../../components/rep-agreements-card/rep-agreements-card';
import SalesFinancingCard from '../../../../../components/sales-financing-card/sales-financing-card';
import { ProjectInfo } from '../../../../../components/project-info/project-info-card';
import { hasAccess } from '../../../../../support/permissions';

import { getPeopleIndex } from '../../../../../support/algolia/get-index';
import { searchClient } from '../../../../../support/algolia/algolia-search-client';

const VALID_STATUSES = ['Set', 'Offer', 'Negotiation'];

const SanitizedItemListCard = ({
  title,
  projectElement,
  itemFinder,
  project,
  dispatch,
  originals,
  onChange,
  changedItems,
}) => {
  const items = project[projectElement] ? project[projectElement] : [];

  const itemSanitizer = function(items) {
    return items.filter(c => !!c._id);
  };

  return (
    <ItemListCard
      title={title}
      items={items}
      onSave={() => {
        const newPrimaryIndex = items.findIndex(item => item.primary);
        if (newPrimaryIndex >= 0) {
          const moveToTop = items.splice(newPrimaryIndex, 1)[0];
          delete moveToTop.primary;
          items.unshift(moveToTop);
        }
        dispatch(updateProject(project._id, { [projectElement]: itemSanitizer(items) })).then(results => {
          notify.show(`${title} Saved`, 'custom', 2000, styles.toast);
          const updated = results.body[projectElement];
          project[projectElement] = updated;
          originals[projectElement] = JSON.parse(JSON.stringify(updated));
          onChange(projectElement);
        });
      }}
      onCancel={() => {
        project[projectElement] = JSON.parse(JSON.stringify(originals[projectElement]));
        onChange(projectElement);
      }}
      onChange={(itemName, index) => {
        if (!project[projectElement]) {
          project[projectElement] = [];
        }
        items[index] = { name: itemName };
        onChange(projectElement);
      }}
      onSelect={(item, index) => {
        items[index] = item;
        onChange(projectElement);
      }}
      onDelete={index => {
        items.splice(index, 1);
        onChange(projectElement);
      }}
      isDirty={changedItems.some(i => {
        return i === projectElement;
      })}
      finder={itemFinder}
      onPrimaryChange={index => {
        items.forEach((other, otherIndex) => {
          if (otherIndex !== index) {
            delete other.primary;
          }
        });
        items[index].primary = true;
        onChange(projectElement);
      }}
      invalidItemInCard={items.some(item => !item._id)}
    />
  );
};

const ReadBy = ({ projectElement, itemFinder, project, dispatch, originals, onChange, changedItems }) => {
  const items = project[projectElement] ? project[projectElement] : [];

  const itemSanitizer = function(items) {
    return items
      .filter(c => !!c._id)
      .map(item => {
        let sanitizedItem = {
          reader: item.reader?._id || item._id,
        };
        if (item.createdAt) {
          sanitizedItem.createdAt = item.createdAt;
        }
        return sanitizedItem;
      });
  };

  return (
    <ReadByCard
      items={items}
      onSave={() => {
        dispatch(updateProject(project._id, { [projectElement]: itemSanitizer(items) })).then(results => {
          notify.show(`Read By Saved`, 'custom', 2000, styles.toast);
          const updated = results.body[projectElement];
          project[projectElement] = updated;
          originals[projectElement] = JSON.parse(JSON.stringify(updated));
          onChange(projectElement);
        });
      }}
      onCancel={() => {
        project[projectElement] = JSON.parse(JSON.stringify(originals[projectElement]));
        onChange(projectElement);
      }}
      onChange={(itemName, index) => {
        if (!project[projectElement]) {
          project[projectElement] = [];
        }
        items[index] = { name: itemName };
        onChange(projectElement);
      }}
      onSelect={(item, index) => {
        items[index] = item;
        onChange(projectElement);
      }}
      onDelete={index => {
        items.splice(index, 1);
        onChange(projectElement);
      }}
      isDirty={changedItems.some(i => {
        return i === projectElement;
      })}
      finder={itemFinder}
      invalidItemInCard={items.some(item => !item._id)}
    />
  );
};

const GeneralExecutiveControl = ({
  dispatch,
  project,
  searchBy,
  type,
  title,
  hideExecType,
  onChange,
  originals,
  changedItems,
}) => (
  <ExecutiveControl
    executives={project[type]}
    title={title}
    searchBy={searchBy}
    dispatch={dispatch}
    notify={() => notify.show(`${title} Saved`, 'custom', 2000, styles.toast)}
    onSave={items => {
      dispatch(updateProject(project._id, { [type]: items })).then(results => {
        notify.show(`${title} Saved`, 'custom', 2000, styles.toast);
        const updated = results.body[type];
        project[type] = updated;
        originals[type] = JSON.parse(JSON.stringify(updated));
        onChange(type);
      });
    }}
    hideExecType={hideExecType}
    isDirty={changedItems.some(i => {
      return i === type;
    })}
    onChanged={(item, index) => {
      project[type][index] = item;
      onChange(type);
    }}
    onCancel={() => {
      project[type] = JSON.parse(JSON.stringify(originals[type]));
      onChange(type);
    }}
    onDelete={index => {
      project[type].splice(index, 1);
      onChange(type);
    }}
  />
);

class Information extends Component {
  constructor(props) {
    super(props);
    const { networks, studios, productionCompanies, talentAgents, literaryAgents, readBy } = props.project;
    const { roles, repAgreements } = props.projects;

    this.state = {
      ...props.project,
      hiddenElements: this.setHiddenElements(),
      origProfiles: props.project.projectProfiles,
      originals: {
        networks: networks ? JSON.parse(JSON.stringify(networks)) : [],
        studios: studios ? JSON.parse(JSON.stringify(studios)) : [],
        productionCompanies: productionCompanies ? JSON.parse(JSON.stringify(productionCompanies)) : [],
        talentAgents: talentAgents ? JSON.parse(JSON.stringify(talentAgents)) : [],
        literaryAgents: literaryAgents ? JSON.parse(JSON.stringify(literaryAgents)) : [],
        roles: roles ? JSON.parse(JSON.stringify(roles)) : [],
        repAgreements: repAgreements ? JSON.parse(JSON.stringify(repAgreements)) : [],
        readBy: readBy ? JSON.parse(JSON.stringify(readBy)) : [],
      },
      isEditingLogLine: false,
      changedItems: [],
    };
  }

  setHiddenElements() {
    const { project } = this.props;
    // const hasTalentAgents = project && Array.isArray(project['talentAgents']) && project['talentAgents'].length > 0;
    switch (project.type) {
      case 'Feature':
        return {
          networks: true,
          repAgreements: !hasAccess('details', 'repAgreements'),
          salesFinancing: !hasAccess('details', 'salesFinancing'),
          // readBy: hasTalentAgents,
        };
      case 'Television':
        return {
          indieAgent: true,
          repAgreements: true,
          salesFinancing: true,
          // readBy: hasTalentAgents,
        };
      case 'Unscripted':
        return {
          repAgreements: !hasAccess('details', 'repAgreements'),
          salesFinancing: !hasAccess('details', 'salesFinancing'),
          // readBy: hasTalentAgents,
        };
      case 'Theatre':
        return {
          networks: true,
          studios: true,
          indieAgent: true,
          repAgreements: true,
          salesFinancing: true,
          // readBy: hasTalentAgents,
        };
      default:
        return { indieAgent: true, repAgreements: true, salesFinancing: true }; // readBy: hasTalentAgents
    }
  }

  elementIsHidden(element) {
    const { hiddenElements } = this.state;
    const isHidden = hiddenElements[element] === true;
    return isHidden;
  }

  render() {
    const {
      onCancel,
      project,
      saveChanges,
      classes,
      dispatch,
      roles,
      castings,
      onChange,
      originals,
      changedItems,
      projectProfiles,
    } = this.props;

    const castingsReadOnly = castings
      ? castings
          .filter(({ status, name, active }) => {
            return (
              name &&
              active &&
              VALID_STATUSES.some(vs => {
                return vs === status;
              })
            );
          })
          .map(casting => {
            const castingToRender = {
              role: { text: casting.name },
              status: { text: casting.status },
              talent: { 
                text: casting.personId ? casting.personId.name : '',
                personId: casting.personId?._id,
              },
            };
            if (casting.emphasizeCastingPerson) {
              castingToRender.talent.style = { fontWeight: 800 };
            }
            return castingToRender;
          })
      : [];

    const positionsReadOnly = roles
      ? roles
          .filter(({ type, active }) => {
            return type && active;
          })
          .map(position => {
            const positionToRender = {
              auspice: { text: position.type },
              status: { text: position.status },
              person: { 
                text: position.personId?.name,
                personId: position.personId?._id,
              },
            };
            if (position.emphasizeRolePerson) {
              positionToRender.person.style = { fontWeight: 800 };
            }
            return positionToRender;
          })
      : [];

    const onClick = (field, value) => {
      project[field] = value;
      this.setState({ [field]: value });
    };

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.loglineCard}>
          <LogLine
            {...this.props}
            setEdit={isEditingLogLine => {
              this.setState({ isEditingLogLine });
            }}
            editing={this.state.isEdit}
          />
        </div>

        <div className={classes.columns}>
          <div className={classes.column}>
            <ProjectInfo
              dispatch={dispatch}
              project={project}
              onCancel={onCancel}
              onClick={onClick}
              saveChanges={saveChanges}
              title="Project Info"
              classes={classes}
            />
            {!this.elementIsHidden('networks') && (
              <GeneralExecutiveControl title="Networks" type="networks" searchBy="Network" {...this.props} />
            )}
            {!this.elementIsHidden('studios') && (
              <GeneralExecutiveControl title="Studios" type="studios" searchBy="Studio" {...this.props} />
            )}
            <GeneralExecutiveControl
              title="Production Companies"
              type="productionCompanies"
              searchBy="Production Companies"
              hideExecType
              {...this.props}
            />
            {!this.elementIsHidden('literaryAgent') && (
              <SanitizedItemListCard
                title="Literary Agents"
                projectElement="literaryAgents"
                itemFinder={name => {
                  const index = getPeopleIndex();
                  const indexSetting = {
                    filters: '(type: Employee)',
                    hitsPerPage: 10,
                    page: 0,
                    restrictSearchableAttributes: ['name'],
                  };
                  return searchClient(index, name, indexSetting).then(({ hits: results }) => results);
                }}
                {...this.props}
              />
            )}
            {!this.elementIsHidden('talentAgent') && (
              <SanitizedItemListCard
                title="Talent Agents"
                projectElement="talentAgents"
                itemFinder={name => {
                  const index = getPeopleIndex();
                  const indexSetting = {
                    filters: '(type: Employee)',
                    hitsPerPage: 10,
                    page: 0,
                    restrictSearchableAttributes: ['name'],
                  };
                  return searchClient(index, name, indexSetting).then(({ hits: results }) => results);
                }}
                {...this.props}
              />
            )}
            {!this.elementIsHidden('indieAgent') && (
              <SanitizedItemListCard
                title="Indie Agents"
                projectElement="indieAgents"
                itemFinder={name => {
                  const index = getPeopleIndex();
                  const indexSetting = {
                    filters: '(type: Employee)',
                    hitsPerPage: 10,
                    page: 0,
                    restrictSearchableAttributes: ['name'],
                  };
                  return searchClient(index, name, indexSetting).then(({ hits: results }) => results);
                }}
                {...this.props}
              />
            )}
            {!this.elementIsHidden('readBy') && (
              <ReadBy
                projectElement="readBy"
                itemFinder={name => {
                  const index = getPeopleIndex();
                  const indexSetting = {
                    filters: '(type: Employee)',
                    hitsPerPage: 10,
                    page: 0,
                    restrictSearchableAttributes: ['name'],
                  };
                  return searchClient(index, name, indexSetting).then(({ hits: results }) => results);
                }}
                {...this.props}
              />
            )}
          </div>

          <div className={classes.column}>
            {!this.elementIsHidden('repAgreements') && (
              <RepAgreementsCard
                items={project.repAgreements}
                onChanged={(item, index) => {
                  project.repAgreements[index] = item;
                  onChange('repAgreements');
                }}
                isDirty={changedItems.some(i => {
                  return i === 'repAgreements';
                })}
                onSave={() => {
                  const repAgreements = project.repAgreements.filter(ra => {
                    return ra.status || ra.fileLocation || ra.summary || ra.executionDate;
                  });
                  dispatch(updateProject(project._id, { repAgreements })).then(results => {
                    notify.show('Rep Agreements Saved', 'custom', 2000, styles.toast);
                    const updated = results.body.repAgreements;
                    project.repAgreements = updated;
                    originals.repAgreements = JSON.parse(JSON.stringify(updated));
                    onChange('repAgreements');
                  });
                }}
                onCancel={() => {
                  project.repAgreements = JSON.parse(JSON.stringify(originals.repAgreements));
                  onChange('repAgreements');
                }}
                onDelete={index => {
                  project.repAgreements[index].active = false;
                  onChange('repAgreements');
                }}
              />
            )}
            {!this.elementIsHidden('salesFinancing') && (
              <SalesFinancingCard
                items={project.salesFinancing}
                onChanged={(item, index) => {
                  if (!project.salesFinancing) {
                    project.salesFinancing = [];
                  }
                  project.salesFinancing[index] = item;
                  onChange('salesFinancing');
                }}
                isDirty={changedItems.some(i => {
                  return i === 'salesFinancing';
                })}
                onSave={() => {
                  const salesFinancing = project.salesFinancing.filter(ra => {
                    return ra.company && ra.relationshipType;
                  });
                  dispatch(updateProject(project._id, { salesFinancing })).then(results => {
                    notify.show('Sales and Financing Saved', 'custom', 2000, styles.toast);
                    const updated = results.body.salesFinancing;
                    project.salesFinancing = updated;
                    originals.salesFinancing = JSON.parse(JSON.stringify(updated));
                    onChange('salesFinancing');
                  });
                }}
                onCancel={() => {
                  project.salesFinancing = JSON.parse(JSON.stringify(originals.salesFinancing));
                  onChange('salesFinancing');
                }}
                onDelete={index => {
                  project.salesFinancing.splice(index, 1);
                  onChange('salesFinancing');
                }}
                onSearchCompanies={(name, type) => {
                  const role = groupRoles[type];
                  return getGroups(name, { role }).then(({ data = [] }) => data);
                }}
              />
            )}
            <ProfileCard projectProfiles={projectProfiles} project={project} />
            {process.env.REACT_APP_SHOW_SEPARATE_POSITIONS_TAB !== true.toString() && (
              <React.Fragment>
                <RoleListCard
                  readonly={process.env.REACT_APP_SHOW_SEPARATE_POSITIONS_TAB === true.toString()}
                  title="Auspices"
                  projectType={project.type}
                  items={roles.filter(r => {
                    if (process.env.REACT_APP_SHOW_SEPARATE_POSITIONS_TAB === true.toString()) {
                      return ['Offer', 'Negotiation', 'Set'].some(s => {
                        return s === r.status;
                      });
                    }
                    return true;
                  })}
                  onSave={() => {
                    const rolesCopy = JSON.parse(JSON.stringify(roles));
                    const rolePromises = rolesCopy.map(r => {
                      if (r.personId) {
                        if (r.personId.name.trim() === '') {
                          r.personId = null;
                        } else {
                          r.personId = r.personId._id;
                        }
                      }
                      r.projectId = project._id;
                      if (!r._id) {
                        //create the role
                        r.projectId = project._id;
                        return dispatch(createRole(r));
                      } else {
                        //update the role
                        return dispatch(updateRole(r._id, r));
                      }
                    });
                    const roleUpdatePromise = Promise.all(rolePromises)
                      .then(() => {
                        return dispatch(projectLoadRoles(project._id));
                      })
                      .then(results => {
                        const freshRoles = results.body.data;
                        if (freshRoles) {
                          roles.splice(0, roles.length);
                          freshRoles.forEach(r => {
                            roles.push(r);
                          });
                        }
                        originals.roles = JSON.parse(JSON.stringify(roles));
                      });
                    return roleUpdatePromise.then(() => {
                      notify.show(`Positions Saved`, 'custom', 2000, styles.toast);
                      onChange('roles');
                    });
                  }}
                  onCancel={() => {
                    roles.splice(0, roles.length);
                    JSON.parse(JSON.stringify(originals.roles)).forEach(r => {
                      roles.push(r);
                    });
                    onChange('roles');
                  }}
                  onChange={(item, index) => {
                    roles[index] = item;
                    onChange('roles');
                  }}
                  onDelete={index => {
                    index < roles.length && (roles[index].active = false);
                    onChange('roles');
                  }}
                  isDirty={changedItems.some(i => {
                    return i === 'roles';
                  })}
                  finder={name => {
                    const index = getPeopleIndex();
                    const indexSetting = {
                      filters: '(projectRoles.k: staff)',
                      hitsPerPage: 10,
                      page: 0,
                      restrictSearchableAttributes: ['name'],
                    };
                    return searchClient(index, name, indexSetting).then(({ hits: results }) => results);
                  }}
                />
                <CastingsCard projectId={project._id} />
              </React.Fragment>
            )}
            {process.env.REACT_APP_SHOW_SEPARATE_POSITIONS_TAB === true.toString() && (
              <React.Fragment>
                {project.type === 'Television' ? (
                  <GroupByInfoListCard
                    title="auspices"
                    cols={['auspice', 'status']}
                    items={positionsReadOnly}
                    groupBy="person"
                    sortBy="auspice"
                    weights={auspiceWeights}
                  />
                ) : (
                  <InfoListCard title="auspices" cols={['auspice', 'status', 'person']} searchAlgolia={true} items={positionsReadOnly} />
                )}
                <InfoListCard title="castings" cols={['role', 'status', 'talent']} searchAlgolia={true} items={castingsReadOnly} />
              </React.Fragment>
            )}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
// UTA grey theme
const theme = createTheme({
  palette: {
    primary: grey,
  },
  overrides: {
    MuiSelect: {
      select: {
        color: '​#808080',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#808080',
        letterSpacing: -0.25,
      },
    },
    MuiOutlinedInput: {
      root: {
        color: '#808080',
      },
    },
  },
});

const styles = {
  columns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginLeft: '20px',
    },
  },
  blockView: {
    color: 'rgb(33,37,41)',
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    borderBottomColor: 'rgb(204,204,204)',
    borderBottomStyle: 'dotted',
    borderBottomWidth: '1px',
  },
  loglineCard: {
    background: 'white',
    marginTop: '5px',
    marginBottom: '25px',
    padding: '15px',
    display: 'flex',
    width: 'inherit',
  },
  toast: { background: '#0F0F0F', text: '#FFFFFF' },
};

const mapStateToProps = state => {
  const { user, projects } = state;
  const { project } = projects;
  const { projectProfiles, talentAgents, literaryAgents } = project;

  return {
    projects,
    project,
    projectProfiles,
    talentAgents,
    literaryAgents,
    user: user.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Information));
