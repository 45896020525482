import React from 'react';
import Chip from '@material-ui/core/Chip';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

import NumberFormat from 'react-number-format';

const { REACT_APP_PROJ_42_FEATURE } = process.env;

function PolarityBadge({ badge }) {
  async function handlePolarity() {
    badge.onPolarityToggle();
  }

  let displayName = badge.name;
  switch (badge.property) {
    case 'budget':
      displayName = (
        <div>
          Budget: <NumberFormat value={badge.name} displayType="text" thousandSeparator={true} prefix="$" />
        </div>
      );
      break;
    default:
      break;
  }

  const label =
    badge.property === 'createdStart' || badge.property === 'createdEnd' || badge.name === true
      ? badge.label
      : displayName;

  return (
    <div className="badge">
      <Chip
        icon={
          badge.exclude ? (
            <RemoveCircle style={{ backgroundColor: 'unset' }} />
          ) : (
            <AddCircle style={{ backgroundColor: 'unset' }} />
          )
        }
        color={badge.exclude ? 'secondary' : 'primary'}
        label={REACT_APP_PROJ_42_FEATURE === true.toString() && badge.custom ? label + ' : ' + badge.custom : label}
        onClick={handlePolarity}
        onDelete={() => {
          badge.onRemoveBadge();
        }}
      />
    </div>
  );
}

export { PolarityBadge };
