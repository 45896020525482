import { algoliaClient } from './algolia-search-client';

const getProjectIndex = filter => {
  if (filter.sort['Name'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_NAME_ASC || '');
  if (filter.sort['Name'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_NAME_DESC || '');
  if (filter.sort['Type'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_TYPE_ASC || '');
  if (filter.sort['Type'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_TYPE_DESC || '');
  if (filter.sort['Production'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_PRODUCTION_ASC || '');
  if (filter.sort['Production'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_PRODUCTION_DESC || '');
  if (filter.sort['Network'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_NETWORK_ASC || '');
  if (filter.sort['Network'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_NETWORK_DESC || '');
  if (filter.sort['Studio'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_STUDIO_ASC || '');
  if (filter.sort['Studio'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_STUDIO_DESC || '');
  if (filter.sort['Director'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_DIRECTOR_ASC || '');
  if (filter.sort['Director'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_DIRECTOR_DESC || '');
  if (filter.sort['Talent'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_TALENT_ASC || '');
  if (filter.sort['Talent'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_TALENT_DESC || '');
  if (filter.sort['Literary'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_LITERARY_ASC || '');
  if (filter.sort['Literary'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_LITERARY_DESC || '');
  if (filter.sort['State'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_STATE_ASC || '');
  if (filter.sort['State'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_STATE_DESC || '');
  if (filter.sort['Classification'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_CLASSIFICATION_ASC || '');
  if (filter.sort['Classification'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_CLASSIFICATION_DESC || '');
  if (filter.sort['Genre'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_GENRE_ASC || '');
  if (filter.sort['Genre'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_GENRE_DESC || '');
  if (filter.sort['Capacity'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_CAPACITY_ASC || '');
  if (filter.sort['Capacity'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_CAPACITY_DESC || '');
  if (filter.sort['Promoters'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_PROMOTERS_ASC || '');
  if (filter.sort['Promoters'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_PROMOTERS_DESC || '');
  if (filter.sort['Agent'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_AGENT_ASC || '');
  if (filter.sort['Agent'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_AGENT_DESC || '');
  if (filter.sort['Created'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_CREATED_ASC || '');
  if (filter.sort['Created'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_CREATED_DESC || '');
  if (filter.sort['Updated'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_UPDATED_ASC || '');
  if (filter.sort['Updated'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_UPDATED_DESC || '');
  if (filter.sort['Start'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_START_ASC || '');
  if (filter.sort['Start'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX_START_DESC || '');
};

const getCastingIndex = filter => {
  if (filter.sort['Project'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_NAME_ASC || '');
  if (filter.sort['Project'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_NAME_DESC || '');
  if (filter.sort['name'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_ROLE_ASC || '');
  if (filter.sort['name'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_ROLE_DESC || '');
  if (filter.sort['roleType'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_ROLETYPE_ASC || '');
  if (filter.sort['roleType'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_ROLETYPE_DESC || '');
  if (filter.sort['talent'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_TALENT_ASC || '');
  if (filter.sort['talent'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_TALENT_DESC || '');
  if (filter.sort['executives'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_EXECUTIVES_ASC || '');
  if (filter.sort['executives'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_EXECUTIVES_DESC || '');
  if (filter.sort['agents'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_AGENTS_ASC || '');
  if (filter.sort['agents'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_AGENTS_DESC || '');
  if (filter.sort['Created'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_CREATED_ASC || '');
  if (filter.sort['Created'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_CREATED_DESC || '');
  if (filter.sort['Updated'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_UPDATED_ASC || '');
  if (filter.sort['Updated'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_UPDATED_DESC || '');
  if (filter.sort['Start'] === 1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_START_ASC || '');
  if (filter.sort['Start'] === -1)
    return algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PROJECTS_CASTINGS_INDEX_START_DESC || '');
};

const getPeopleIndex = () => algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX_NAME || '');

const getCompaniesIndex = () => algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX || '');

export { getProjectIndex, getCastingIndex, getPeopleIndex, getCompaniesIndex };
