import React, { Component } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { Helmet } from 'react-helmet';
import { Row } from 'reactstrap';
import { NavLink, Route, withRouter } from 'react-router-dom';
import { ProjectView } from './project-view';
import PeopleView from './people-view';
import { notify } from 'react-notify-toast';

import { colors, elements, sizes } from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';
import { loadTracking, updateTracking } from '@united-talent-agency/julius-frontend-store';
import ListName from '../../../components/list-name/list-name';

const PAGE_SIZE = 100;

const Menu = ({ styles, match }) => {
  const listId = match.params.listId;
  return (
    <div className={styles.menu}>
      <Row className={styles.menuLeft}>
        <NavLink exact={true} to={`/list/${listId}`} className={styles.menuItem} activeClassName={styles.activeItem}>
          Projects
        </NavLink>
        <NavLink to={`/list/${listId}/people`} className={styles.menuItem} activeClassName={styles.activeItem}>
          People
        </NavLink>
      </Row>
    </div>
  );
};

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrinting: false,
      selectedProjects: [],
      isModalOpen: false,
      isListManagerCollapsed: true,
      isPrintManagerCollapsed: true,
      view: 'list',
      results: [],
      name: '',
      person: {},
      tracking: {},
      isLoading: true,
      sortColumn: 'Name',
      sortDirection: 1,
      pageSize: PAGE_SIZE,
      skip: 0,
      isPaginate: false,
      isSort: false,
    };
    this.show = notify.createShowQueue();
    this.onPersonAdded = this.onPersonAdded.bind(this);
    this.onPersonRemoved = this.onPersonRemoved.bind(this);
    this.loadTrackings = this.loadTrackings.bind(this);
    this.projectsChanged = this.projectsChanged.bind(this);
    this.archivedChanged = this.archivedChanged.bind(this);
  }

  componentDidMount() {
    this.loadTrackings();
  }

  componentDidUpdate(prevProps, previousState) {
    if (this.props.match.params.listId !== prevProps.match.params.listId) {
      this.loadTrackings();
    } else if (
      this.state.skip !== previousState.skip ||
      this.state.sortDirection !== previousState.sortDirection ||
      this.state.sortColumn !== previousState.sortColumn
    ) {
      this.loadTrackings();
    }
  }

  loadTrackings(isUpdate = false) {
    this.setLoader(isUpdate);
    let page = isUpdate ? 0 : this.state.skip ? this.state.skip / PAGE_SIZE : 0;
    const id = this.props.match.params.listId;
    const sortBy = this.state.sortColumn;
    const direction = Number(this.state.sortDirection);

    const url = `${id}?page=${page}&limit=${PAGE_SIZE}&sortBy=${sortBy}&direction=${direction}`;
    this.props.dispatch(loadTracking(url)).then(result => {
      const existingProjects = this.state.tracking.projects ? this.state.tracking.projects : [];
      const newProjects = existingProjects.concat(result.body ? result.body.projects : []);
      const data = { ...result.body, projects: newProjects };
      this.setProjects(data);
    });
  }

  setLoader = isUpdate => {
    if ((this.state.isPaginate || this.state.isSort) && !isUpdate) return;
    this.setState({ isLoading: true });
  };

  setProjects = projects => {
    this.state.isLoading
      ? this.setState({ tracking: projects, isLoading: false })
      : this.setState({ tracking: projects });
  };

  projectsChanged(projectList) {
    const id = this.props.match.params.listId;
    return this.props.dispatch(updateTracking(id, { projects: projectList })).then(() => {
      this.setState({ tracking: {} });
      return this.loadTrackings(true);
    });
  }

  archivedChanged() {
    const id = this.props.match.params.listId;
    const { tracking } = this.state;
    const archived = !tracking.archived;
    this.props.dispatch(updateTracking(id, { archived })).then(() => {
      tracking.archived = archived;
      this.setState({ tracking });
    });
  }

  onPersonAdded(person) {
    const { tracking } = this.state;
    const { dispatch } = this.props;
    if (tracking.people === undefined) {
      tracking.people = [];
    }
    if (
      !tracking.people.some(p => {
        return p?._id === person?._id;
      })
    ) {
      tracking.people.push(person);
      return dispatch(updateTracking(tracking._id, { people: tracking.people })).then(() => {
        this.setState({ tracking });
      });
    }
  }

  onPersonRemoved(person) {
    const { tracking } = this.state;
    const { dispatch } = this.props;
    const trackingCopy = Object.assign({}, tracking);

    tracking.people &&
      (trackingCopy.people = tracking.people.filter(p => {
        return p?._id !== person?._id;
      }));
    return dispatch(updateTracking(tracking._id, { people: trackingCopy.people })).then(() => {
      this.setState({ tracking: trackingCopy });
    });
  }

  render() {
    const { styles, dispatch, match } = this.props;
    const { tracking, isLoading } = this.state;
    return isLoading ? (
      <div className={styles.spinnerContainer}>
        <Spinner size={100} />
      </div>
    ) : (
      <div className={styles.outerContainer}>
        <Helmet>
          <title>{(tracking && tracking.name) || 'LIST'}</title>
        </Helmet>
        <div className={styles.header}>
          <ListName
            archived={tracking.archived}
            name={tracking.name}
            saveChanges={async name => {
              await dispatch(updateTracking(tracking._id, { name }));
              this.show(`${name} saved`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
            }}
          />
          <Menu styles={styles} match={match} />
          <Route
            path="/list/:listId/people"
            render={props => (
              <PeopleView
                {...props}
                tracking={tracking}
                style={this.props.style}
                onPersonAdded={this.onPersonAdded}
                onPersonRemoved={this.onPersonRemoved}
                dispatch={dispatch}
              />
            )}
          />
          <Route
            exact={true}
            path="/list/:listId"
            render={props => (
              <ProjectView
                {...props}
                tracking={tracking}
                sortColumn={this.state.sortColumn}
                sortDirection={this.state.sortDirection}
                onProjectsChanged={this.projectsChanged}
                onArchivedChanged={this.archivedChanged}
                fetchNewProjects={skip => this.setState({ skip: skip, isPaginate: true, isSort: false })}
                onSortChanged={(column, sort) =>
                  this.setState({
                    sortColumn: column,
                    sortDirection: sort,
                    skip: 0,
                    tracking: { ...this.state.tracking, projects: [] },
                    isSort: true,
                    isPaginate: false,
                  })
                }
                style={this.props.style}
                dispatch={dispatch}
              />
            )}
          />
        </div>
      </div>
    );
  }
}

const withStyles = styled({
  outerContainer: {
    minWidth: 800,
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50vw',
    top: '50vh',
  },
  header: {
    background: colors.contentBackground,
    padding: '0 20px',
  },
  menu: helpers.merge(
    {
      display: 'flex',
    },
    sizes.container
  ),
  menuItem: helpers.merge(elements.menuItem, {
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
  }),
  activeItem: elements.activeMenuItem,
  nameInput: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const withState = connect(state => state);

export default withRouter(withState(withStyles(ListView)));
