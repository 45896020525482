import { debounce, uniqBy, sortBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { notify } from 'react-notify-toast';
import hash from 'object-hash';
import { elements, sizes } from '@united-talent-agency/julius-frontend-components';
import { recordEvent, projectSearchProjects, createProjectGrid } from '@united-talent-agency/julius-frontend-store';
import { getGroups, groupRoles } from '../../../../../api/groups';
import CollapsedFilters from '../../../../../components/collapse-filters/collapse-filters';
import ListManager from '../../../../../components/list-manager/list-manager';
import GridManager from '../../../../../components/grid-manager/grid-manager';
import PrintManager from '../../../../../components/print-manager/print-manager';
import ProjectFilterForm from '../../../../../components/project-filter-form/project-filter-form';
import ProjectsSearch from '../../../../../components/search/projects-search';
import { downloadFile } from '../../../../../support/download';
import { projectSearchIndexes, projectTypes } from '../../../../../support/items/filter-types';
import { ProjectsBadgeBuilder } from '../../../../../components/filter-model/filter-badge-builder';
import CountLabel from '../../../../../components/count-label/count-label';
import { Multiselect } from '../../../../../components/multiselect/Multiselect';
import { searchClient } from '../../../../../support/algolia/algolia-search-client';
import { transformMongoProjectFilters } from '../../../../../support/algolia/transform-filters';
import Grid from './../algolia/alg-search-projects';
import { getProjectIndex, getPeopleIndex } from '../../../../../support/algolia/get-index';

const PAGE_SIZE = 100;
const MAX_RESULTS = 2000;
class Search extends Component {
  constructor(props) {
    super(props);
    const { grid, filterModel } = this.props;
    const filter = grid ? grid.projectFilter : filterModel;
    this.state = {
      projectName: filter && filter.name,
      filterModel: props.filterModel,
      areFiltersCollapsed: false,
      isListManagerCollapsed: true,
      isGridManagerCollapsed: true,
      isPrintManagerCollapsed: true,
      isPrinting: false,
      selectAll: false,
      selection: new Set(),
      lastSearchHash: '',
      sortColumn: 'Name',
      sortDirection: 1,
      filterBadges: [],
      gettingData: false,
      projectSearchIndexes: projectSearchIndexes,
    };
    this.state.filterModel.limit = PAGE_SIZE;
    this.filterProjects = this.filterProjects.bind(this);
    this.createGrid = this.createGrid.bind(this);
    this.searchCompany = this.searchCompany.bind(this);
    this.searchPerson = this.searchPerson.bind(this);
    this.nameFinder = this.nameFinder.bind(this);
    this.clearFilterModel = this.clearFilterModel.bind(this);
    this.getSelectedProjects = this.getSelectedProjects.bind(this);
    this.show = notify.createShowQueue();
    this.handleNameChange = this.handleNameChange.bind(this);
    this.nameChangeDebounced = debounce(this.filterProjects, 500);
  }

  handleNameChange(projectName) {
    const filterModel = this.state.filterModel;
    filterModel['name'] = projectName;
    this.setState({
      projectName,
      filterModel,
    });
    this.nameChangeDebounced(filterModel);
  }

  componentDidMount() {
    const { grid, filterModel } = this.props;
    const filter = grid ? grid.projectFilter : filterModel;
    this.filterProjects(filter);
  }

  hashFilter(filter) {
    const exclusions = function(key) {
      if (key === 'limit' || key === 'skip' || key === 'sort') {
        return true;
      }
      return false;
    };
    return hash(filter, { excludeKeys: exclusions });
  }

  async filterProjects(filter, append = false) {
    const { lastSearchHash, gettingData, sortColumn, sortDirection, projects } = this.state;
    const { dispatch } = this.props;
    if (gettingData) {
      return;
    } else {
      this.setState({ gettingData: true });
    }

    //reset selection if search changes - ignore sorting/pagination
    const searchHash = this.hashFilter(filter);
    if (searchHash !== lastSearchHash) {
      this.setState({
        selectAll: false,
        selection: new Set(),
        isPrintManagerCollapsed: true,
        isListManagerCollapsed: true,
        isGridManagerCollapsed: true,
        lastSearchHash: searchHash,
      });
    }
    if (!filter.name) {
      filter.name = '';
    }

    //first-time search default
    if (!filter.sort) {
      filter.sort = { [sortColumn]: sortDirection };
    }

    if (append) {
      filter.skip = projects.length;
    } else {
      delete filter.skip;
    }

    const filterBadges = this.buildProjectBadgesFromFilter(filter);

    let page = filter.skip ? filter.skip / PAGE_SIZE : 0;

    const index = getProjectIndex(filter);

    const filters = transformMongoProjectFilters(filter);

    let indexSetting = {
      filters,
      hitsPerPage: PAGE_SIZE,
      page,
    };

    let searchableAttributes = [];

    // we only  need to restrict index if one the indexes have been selected
    if (filter.projectSearchIndexes && filter.projectSearchIndexes.length === 1 && filter.name !== undefined) {
      filter.projectSearchIndexes.forEach(index => {
        if (index === 'Project Names') {
          searchableAttributes.push('name');
        }
        if (index === 'Log Lines') {
          searchableAttributes.push('log_line');
        }
      });
      indexSetting.restrictSearchableAttributes = searchableAttributes;
    }

    const result = await searchClient(index, filter.name, {
      ...indexSetting,
    });

    dispatch(projectSearchProjects({ ...filter, isAlgoliaSearch: true }));

    const existingProjects = append ? Object.assign([], projects) : [];
    const newProjects = append
      ? existingProjects.concat(result.hits ? result.hits : [])
      : result.hits
        ? result.hits
      : [];

    let resultCount = result.nbHits;

    if ((filters === '(active:true)' || filters === '(active:false)') && !filter.name) {
      const getFacetValues = async (client, filters) => {
        const facetValues = await client.searchForFacetValues('active', '', {
          filters,
        });

        return facetValues;
      };
      const facetValues = await getFacetValues(index, filters);
      page = Math.trunc(facetValues.facetHits[0]?.count / 100);

      const resultForActArch = await searchClient(index, filter.name, {
        filters,
        hitsPerPage: PAGE_SIZE,
        page,
      });
      resultCount = resultForActArch.nbHits;
    }

    this.setState({
      filterBadges: filterBadges.badges,
      projects: newProjects,
      gettingData: false,
      resultCount,
    });

    let newSearchResult = {
      type: 'PROJECT_SEARCH_PROJECTS',
      body: {
        data: result.hits,
      },
    };
    dispatch(projectSearchProjects({ ...filter, isAlgoliaSearch: true }));
    return newSearchResult;
  }

  buildProjectBadgesFromFilter(filterModel) {
    const filterBadges = new ProjectsBadgeBuilder(filterModel);

    filterBadges.buildProjects();
    filterBadges.badges.forEach(badge => {
      badge.onRemoveBadge = () => this.onRemoveBadge(badge, filterModel);
      badge.onPolarityToggle = () => this.onPolarityToggle(badge, filterModel);
    });
    return filterBadges;
  }

  // eslint-disable-next-line no-undef
  onPolarityToggle = (badge, filterModel) => {
    const filterProperty = filterModel[badge.property];
    if (filterProperty.include && badge.exclude === false) {
      const excludeArray = filterProperty.exclude || [];
      const includeIndex = filterProperty.include.findIndex(property => {
        return property.name ? property.name === badge.name : property === badge.name;
      });
      const includeItemToToggle = filterProperty.include.splice(includeIndex, 1)[0];
      excludeArray.push(includeItemToToggle);
      filterProperty.exclude = excludeArray;
      badge.exclude = true;
    } else if (filterProperty.exclude && badge.exclude === true) {
      const includeArray = filterProperty.include || [];
      const excludeIndex = filterProperty.exclude.findIndex(property => {
        return property.name ? property.name === badge.name : property === badge.name;
      });
      const excludeItemToToggle = filterProperty.exclude.splice(excludeIndex, 1)[0];
      includeArray.push(excludeItemToToggle);
      filterProperty.include = includeArray;
      badge.exclude = false;
    }
    this.filterProjects(filterModel);
  };

  // eslint-disable-next-line no-undef
  onRemoveBadge = async badge => {
    const { dispatch, user } = this.props;
    const filterModel = this.state.filterModel;
    const filterProperty = filterModel[badge.property];

    if (!filterProperty.include && !filterProperty.exclude) {
      delete filterModel[badge.property];
      this.filterProjects(filterModel);
    } else {
      if (badge.exclude) {
        const excludeArray = filterProperty.exclude.filter(property =>
          property.name ? property.name !== badge.name : property !== badge.name
        );
        filterProperty.exclude = excludeArray;
      } else if (!badge.exclude) {
        const includeArray = filterProperty.include.filter(property =>
          property.name ? property.name !== badge.name : property !== badge.name
        );
        filterProperty.include = includeArray;
      }
    }

    dispatch(
      recordEvent(
        'projects.search.remove-badge',
        'projects-web',
        'info',
        '1',
        [{ data: `${badge.name}_${badge.label}` }],
        user._id
      )
    );

    await this.filterProjects(filterModel);
  };

  render() {
    const props = this.props;
    const { desk, dispatch, styles, user, grid, canEdit } = props;
    const {
      areFiltersCollapsed,
      isPrintManagerCollapsed,
      isListManagerCollapsed,
      isGridManagerCollapsed,
      selection,
      selectAll,
      resultCount,
      filterModel = {},
      projects,
    } = this.state;
    const filter = grid ? grid.projectFilter : filterModel;
    if (!desk.current) {
      return (
        <div className="my-5 text-center">
          <h5>No Desk Assigned</h5>
        </div>
      );
    }
    const columnWidths = {
      tableColumn: areFiltersCollapsed ? styles.tableColumnCollapsed : styles.tableColumn,
      filterColumn: areFiltersCollapsed ? styles.filterColumnCollapsed : styles.filterColumn,
    };

    const selectedTypes = projectTypes.filter(type => {
      return (filter.types || []).some(fType => fType === type);
    });

    const selectedIndexes = projectSearchIndexes.filter(item => {
      return (filter.projectSearchIndexes || []).some(fType => fType === item);
    });

    const isButtonDisabled =
      !projects ||
      projects.length === 0 ||
      (!selectAll && selection && selection.size === 0) ||
      (selectAll && selection && projects && selection.size === projects.length);

    return (
      <div>
        <div className={styles.content}>
          <div className="m-3">
            <div className="mx-0 mt-0 mb-0 row">
              <div className={styles.typeSelectionColumn}>
                <div className="pl-0 pr-2 col w-100">
                  <Multiselect
                    title="Type"
                    options={projectTypes}
                    selectedOptions={selectedTypes}
                    onSelect={option => {
                      this.onChangeProjectTypes(option);
                    }}
                  />
                </div>
              </div>

              <div className={styles.searchInputColumn}>
                <ProjectsSearch
                  projectName={this.state.projectName}
                  onNameChange={this.handleNameChange}
                  filterModel={filter}
                  handleProjectIndexChange={this.handleProjectIndexChange}
                  selectedIndexes={selectedIndexes}
                />
                <CountLabel label="result" count={resultCount} className={styles.countLabel} />
              </div>
            </div>

            <div className={styles.container}>
              <div className={columnWidths.filterColumn}>
                {desk.current && !areFiltersCollapsed && (
                  <ProjectFilterForm
                    collapseEvent={() => this.collapseEvent('filter')}
                    deskId={desk.current._id}
                    filterModel={filter}
                    dispatch={dispatch}
                    filterProjects={this.filterProjects}
                    searchNames={this.nameFinder}
                    searchCompany={this.searchCompany}
                    filterBadges={this.state.filterBadges}
                    styles={this.props.styles}
                    recordEvent={value => {
                      dispatch(
                        recordEvent('projects.search.filter-event', 'projects-web', 'info', '1', [value], user._id)
                      );
                    }}
                  />
                )}
                {areFiltersCollapsed && <CollapsedFilters expandEvent={() => this.expandEvent('filter')} />}
              </div>
              <div className={columnWidths.tableColumn}>
                <Grid
                  projects={projects}
                  filterModel={filter}
                  selectAll={selectAll}
                  selection={selection}
                  onSortChanged={(column, direction) => {
                    this.setState({ sortColumn: column, sortDirection: direction });
                    // weird bug that doenst redefine the state and props inside this.
                    const { grid: _grid } = props;
                    const { filterModel: _filterModel } = this.state;
                    const _filter = grid ? _grid.projectFilter : _filterModel;
                    _filter.sort = { [column]: direction };
                    this.filterProjects(_filter);
                  }}
                  sortColumn={this.state.sortColumn}
                  sortDirection={this.state.sortDirection}
                  resultCount={resultCount}
                  maxCount={MAX_RESULTS}
                  onFetchMore={() => {
                    this.filterProjects(filter, true);
                  }}
                  onSelectedChanged={this.onSelectedChanged}
                  onSelectedChangedAll={this.onSelectedChangedAll}
                  filterBadges={this.state.filterBadges}
                  resetFilters={this.resetFilters}
                  buttons={[
                    {
                      name: 'Print',
                      onClick: () => this.expandEvent('print'),
                      icon: this.state.isPrinting ? 'circle-o-notch fa-spin' : 'print',
                      hidden: !isPrintManagerCollapsed,
                      disabled: isButtonDisabled,
                    },
                    {
                      name: 'Add to List',
                      onClick: () => this.expandEvent('list'),
                      icon: 'plus',
                      hidden: !isListManagerCollapsed,
                      disabled: isButtonDisabled,
                    },
                    {
                      name: 'Save as Grid',
                      onClick: () => this.expandEvent('grid'),
                      icon: 'save',
                      hidden: !isGridManagerCollapsed || filterModel.filterType === 'grid',
                    },
                  ]}
                />
              </div>
              {!isListManagerCollapsed && (
                <div className={styles.listColumn}>
                  <ListManager
                    desk={desk.current}
                    user={user}
                    filters={filterModel}
                    dispatch={dispatch}
                    projectsFunc={this.getSelectedProjects}
                    onCancel={() => {
                      this.collapseEvent('list');
                    }}
                    onCreated={list => {
                      this.collapseEvent('list');
                      this.show(`${list.name} created`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
                      window.open(`/list/${list._id}`, list._id);
                    }}
                    onUpdated={() => {
                      this.collapseEvent('list');
                      this.show(`List updated`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
                    }}
                    selectAll={selectAll}
                    limit={resultCount}
                  />
                </div>
              )}
              {!isPrintManagerCollapsed && (
                <PrintManager
                  grid={grid}
                  canEdit={canEdit}
                  savedOptions={grid && grid.printOptions}
                  updateGrid={this.props.updateGrid}
                  collapseEvent={() => this.collapseEvent('print')}
                  print={this.print}
                  userInTalent={user && user.personId && user.personId.department === 'Talent'}
                  docCount={this.state.resultCount}
                />
              )}
              {!isGridManagerCollapsed && (
                <div className={styles.listColumn}>
                  <GridManager
                    collapseEvent={() => this.collapseEvent('grid')}
                    createGrid={this.createGrid}
                    printOptions={filterModel.printOptions}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line no-undef
  resetFilters = async () => {
    const { dispatch } = this.props;
    const filterModel = {};
    filterModel.type = this.state.filterModel.type;
    filterModel.active = true;
    filterModel.projectSearchIndexes = projectSearchIndexes;
    await dispatch(projectSearchProjects({ ...filterModel, isAlgoliaSearch: true }));
    this.setState({ filterModel, projectName: '' });
    await this.filterProjects(filterModel);
  };

  // eslint-disable-next-line no-undef
  onChangeProjectTypes = type => {
    const { grid } = this.props;
    const { filterModel = {} } = this.state;
    const filter = grid ? grid.projectFilter : filterModel;
    const previousTypes = filter.types || [];
    const newTypes =
      previousTypes.indexOf(type) > -1 ? previousTypes.filter(t => t !== type) : previousTypes.concat(type);
    Object.keys(filter).forEach(key => {
      if (key !== 'projectSearchIndexes') delete filter[key];
    });

    filter.types = newTypes;
    filter.active = true;
    this.setState({ projectName: '' });
    if (!grid) {
      this.setState({ filterModel: filter });
    }
    return this.filterProjects(filter);
  };

  handleProjectIndexChange = currentIndex => {
    const { grid } = this.props;
    const { filterModel = {} } = this.state;
    const filter = grid ? grid.castingFilter : filterModel;
    const previousIndexes = filter.projectSearchIndexes || [];

    let newIndexes;

    if (currentIndex === 'All') {
      if (previousIndexes.includes('All')) {
        newIndexes = [];
      } else {
        newIndexes = [...projectSearchIndexes];
      }
    } else {
      newIndexes =
        previousIndexes.indexOf(currentIndex) > -1
          ? previousIndexes.filter(t => t !== currentIndex)
          : previousIndexes.concat(currentIndex);
    }

    filter.projectSearchIndexes = newIndexes;
    filter.active = true;

    if (!grid) {
      this.setState({ filterModel: filter });
    }
    return this.filterProjects(filterModel);
  };

  print = (options = {}) => {
    const { grid } = this.props;
    const { selection, selectAll } = this.state;
    const projects = Array.from(selection);
    let fileName = grid ? grid.name : 'Search Results';
    if (options.saveAs) {
      fileName = options.saveAs;
    }
    this.performDownload(
      'v2/print-report',
      fileName,
      {
        options,
        all: selectAll,
        projects,
        gridProjectFilter: grid?.projectFilter,
      },
      [{ printType: grid ? 'project-grid' : 'search' }]
    );
  };

  // eslint-disable-next-line no-undef
  collapseEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: true });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isPrintManagerCollapsed: true });
        break;
      case 'grid':
        this.setState({ isGridManagerCollapsed: true });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-undef
  expandEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: false });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: false, isPrintManagerCollapsed: true, isGridManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isListManagerCollapsed: true, isPrintManagerCollapsed: false, isGridManagerCollapsed: true });
        break;
      case 'grid':
        this.setState({ isListManagerCollapsed: true, isPrintManagerCollapsed: true, isGridManagerCollapsed: false });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-undef
  createGrid = async (title, printOptions) => {
    const { dispatch, desk } = this.props;
    const { filterModel } = this.state;
    const filterObject = (
      await dispatch(
        createProjectGrid({
          projectFilter: filterModel,
          name: title,
          printOptions: printOptions,
          deskId: desk.current._id,
        })
      )
    ).body;
    this.show(`${filterObject.name} created`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
    window.open(`/grid/project_grid/${filterObject._id}`, filterObject._id);
  };

  getSelectedProjects() {
    const { selectAll, selection, projects } = this.state;
    const selectedProjects = projects
      .filter(project => {
        return (selectAll && !selection.has(project._id)) || (!selectAll && selection.has(project._id));
      })
      .map(project => {
        return project._id;
      });
    return selectedProjects;
  }

  // eslint-disable-next-line no-undef
  setIsDownloading = (url, isDownloading) => {
    this.setState({ isPrinting: isDownloading });
  };

  performDownload(url, fileName, options, query) {
    const { user, desk } = this.props;
    if (this.state.isPrinting) {
      return;
    }

    downloadFile(url, fileName, user, desk.current, this.setIsDownloading, options, query);
  }

  // eslint-disable-next-line no-undef
  onSelectedChanged = projectId => {
    const { selection } = this.state;

    if (selection.has(projectId)) {
      selection.delete(projectId);
    } else {
      selection.add(projectId);
    }
    this.setState({ selection });
  };

  // eslint-disable-next-line no-undef
  onSelectedChangedAll = selected => {
    this.setState({ selectAll: selected, selection: new Set() });
  };

  async searchPerson(value) {
    const index = getPeopleIndex();
    const indexSetting = {
      filters: '(type: Employee)',
      hitsPerPage: 10,
      page: 0,
      restrictSearchableAttributes: ['name'],
    };
    const { hits: results } = await searchClient(index, value, indexSetting);
    const unique = uniqBy(results, 'name');
    return sortBy(unique, 'name');
  }

  async nameFinder(name) {
    const index = getPeopleIndex();
    const indexSetting = {
      filters:
        "(projectRoles.k: 'agent') OR (projectRoles.k: 'staff') OR (projectRoles.k: 'talent') OR (projectRoles.k: 'executive') OR (projectRoles.k: 'noteMaker') OR (projectRoles.k: 'noteSubject')",
      hitsPerPage: 10,
      page: 0,
      restrictSearchableAttributes: ['name'],
    };
    if (name !== '') {
      const { hits: results } = await searchClient(index, name, indexSetting);
      return results;
    }
    return [];
  }

  async searchCompany(value, type) {
    const role = groupRoles[type] || groupRoles.AnyRole;
    const { data } = await getGroups(value, { role });
    return data;
  }

  async clearFilterModel() {
    const { filterBadges } = this.props;
    const badgesToKeep = ['type', 'active'];
    for (const filterBadge of filterBadges) {
      if (!badgesToKeep.includes(filterBadge.property)) {
        await filterBadge.onRemoveBadge();
      }
    }
  }
}

const withStyles = styled({
  body: {
    margin: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  tableColumn: {
    flex: 0.815,
  },
  filterColumn: {
    display: 'flex',
    flex: 0.185,
    flexDirection: 'column',
  },
  typeSelectionColumn: {
    display: 'flex',
    flex: 0.185,
    flexDirection: 'column',
    marginBottom: '0px',
  },
  searchInputColumn: {
    display: 'flex',
    flex: 0.815,
    flexDirection: 'column',
    marginBottom: '0px',
  },
  tableColumnCollapsed: {
    display: 'flex',
    flex: 0.99,
    flexDirection: 'column',
  },
  filterColumnCollapsed: {
    width: 25,
    flexDirection: 'column',
  },
  menuItem: helpers.merge(elements.menuItem, {
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
  }),
  activeItem: helpers.merge(elements.activeMenuItem, {}), // TODO: Fix coloring on links
  menu: helpers.merge(
    {
      display: 'flex',
      marginBottom: 20,
      marginLeft: 30,
    },
    sizes.container
  ),
  countLabel: { marginLeft: 8, color: '#666', fontSize: '10pt' },
});

const mapStateToProps = state => {
  return { user: state.user, desk: state.desk };
};

export default connect(mapStateToProps)(withStyles(Search));
