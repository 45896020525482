import React, { Component } from 'react';
import { styled } from 'react-free-style';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import TextField from '@material-ui/core/TextField';

import { colors } from '../../styles/colors';
import FilterCheckbox from '../filter-checkbox/filter-checkbox';
import { hasAccess } from '../../support/permissions';

const indexOptions = [
  { type: '', label: 'None' },
  { type: 'Project_Title_Index', label: 'Project Title' },
  { type: 'Literary_Agent_Index', label: 'Literary Agent' },
  { type: 'Talent_Agent_Index', label: 'Talent Agent' },
  { type: 'Indie_Agent_Index', label: 'Indie Agent' },
  { type: 'Indie_Status_Index', label: 'Indie Status', hasAccess: hasAccess('printing', 'groupByIndieStatus') },
  { type: 'Production_Company_Index', label: 'Production Company' },
  { type: 'Studio_Index', label: 'Studio' },
  { type: 'Network_Index', label: 'Network' },
  { type: 'Director_Index', label: 'Director' },
  { type: 'Casting_Director_Index', label: 'Casting Director' },
  { type: 'Television_Status_Index', label: 'Television Status' },
];

export class PrintOptions extends Component {
  constructor(props) {
    super(props);

    this.state = props.savedOptions || {
      executives: true,
      includeLiteraryExecs: true,
      includeTalentExecs: true,
      staff: true,
      staffByPerson: false,
      includeStaffAgencies: false,
      cast: true,
      index: '',
      talentNotes: false,
      literaryNotes: false,
      indieNotes: false,
      includeInternalLiteraryNotes: false,
      includeDatesLiteraryNotes: false,
      showRolesTable: props.userInTalent || false,
      showRolesSelected: !!props.isCastingResults,
      showRolesTableAgency: true,
      suppressFormatting: false,
      firstElement: false,
      watermark: true,
      repAgreements: false,
      budget: true,
      internalHeader: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      agents: true,
      talentAgents: true,
      literaryAgents: true,
      indieAgents: true,
      budgetNotes: false,
      director: false,
      castingDirector: false
    };
    this.state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    this.props.onSelectionsChanged && this.props.onSelectionsChanged(this.state);
  }

  // eslint-disable-next-line no-undef
  onCheckboxesChanged = async (field, value) => {
    let newValue;

    switch (field) {
      case 'executives':
        newValue = { executives: value, includeLiteraryExecs: value, includeTalentExecs: value };
        break;
      case 'includeLiteraryExecs':
        newValue = { includeLiteraryExecs: value };
        break;
      case 'includeTalentExecs':
        newValue = { includeTalentExecs: value };
        break;
      case 'staff':
        newValue = { staff: value, includeStaffAgencies: false, staffByPerson: false };
        break;
      case 'includeStaffAgencies':
        newValue = { includeStaffAgencies: value };
        break;
      case 'staffByPerson':
        newValue = { staffByPerson: value };
        break;
      case 'cast':
        newValue = { cast: value };
        break;
      case 'talentNotes':
        newValue = { talentNotes: value };
        break;
      case 'indieNotes':
        newValue = { indieNotes: value };
        break;
      case 'literaryNotes':
        newValue = { literaryNotes: value, includeInternalLiteraryNotes: false, includeDatesLiteraryNotes: false };
        break;
      case 'includeInternalLiteraryNotes':
        newValue = { includeInternalLiteraryNotes: value };
        break;
      case 'includeDatesLiteraryNotes':
        newValue = { includeDatesLiteraryNotes: value };
        break;
      case 'showRolesTable':
        newValue = { showRolesTable: value, showRolesTableAgency: value };
        break;
      case 'showRolesSelected':
        newValue = { showRolesSelected: value };
        break;
      case 'showRolesTableAgency':
        newValue = { showRolesTableAgency: value };
        break;
      case 'clientBold':
        newValue = { suppressFormatting: !value };
        break;
      case 'watermark':
        newValue = { watermark: value };
        break;
      case 'firstElement':
        newValue = { firstElement: value };
        break;
      case 'repAgreements':
        newValue = { repAgreements: value };
        break;
      case 'agents':
        newValue = { agents: value, talentAgents: value, literaryAgents: value, indieAgents: value };
        break;
      case 'talentAgents':
        newValue = { talentAgents: value };
        break;
      case 'literaryAgents':
        newValue = { literaryAgents: value };
        break;
      case 'indieAgents':
        newValue = { indieAgents: value };
        break;
      case 'budget':
        newValue = { budget: value, budgetNotes: false };
        break;
      case 'budgetNotes':
        newValue = { budgetNotes: value };
        break;
      case 'director':
        newValue = { director: value };
        break;
      case 'castingDirector':
        newValue = { castingDirector: value };
        break;
      default:
        return;
    }

    this.setState(newValue, () => {
      this.props.onSelectionsChanged(this.state);
    });
  };

  // Changes to the radio group to select an index (or none)
  // update the print configuration
  // eslint-disable-next-line no-undef
  onIndexRadioGroupChanged = async (event, value) => {
    this.setState({ index: value }, () => {
      this.props.onSelectionsChanged(this.state);
    });
  };

  render() {
    const { styles, indexOptionsDisabled, isCastingResults, onSelectionsChanged, _hasAccess = hasAccess } = this.props;
    const {
      index,
      showRolesTable,
      showRolesSelected,
      showRolesTableAgency,
      firstElement,
      includeStaffAgencies,
      literaryNotes,
      includeInternalLiteraryNotes,
      includeDatesLiteraryNotes,
      includeLiteraryExecs,
      includeTalentExecs,
      staff,
      staffByPerson,
      cast,
      executives,
      talentNotes,
      indieNotes,
      suppressFormatting,
      watermark,
      repAgreements,
      agents,
      talentAgents,
      literaryAgents,
      indieAgents,
      budget,
      budgetNotes,
      director,
    } = this.state;
    const primaryIndexOptionDisabled =
      index === '' ||
      index === 'Project_Title_Index' ||
      index === 'Indie_Status_Index' ||
      index === 'Television_Status_Index';
    const indexDisplayNameMap = {
      Project_Title_Index: 'project',
      Talent_Agent_Index: 'agent',
      Literary_Agent_Index: 'agent',
      Indie_Agent_Index: 'agent',
      Studio_Index: 'studio',
      Production_Company_Index: 'production company',
      Network_Index: 'network',
      Director_Index: 'director',
      Casting_Director_Index: 'casting director',
    };
    const indexDisplayName = index && indexDisplayNameMap[index];

    return (
      <React.Fragment>
        <TextField
          label="Save As (Optional)"
          onChange={event => onSelectionsChanged({ ...this.state, ...{ saveAs: event.target.value } })}
        />
        <div className={styles.subHeader}>Include:</div>
        <div className={styles.container}>
          <FilterCheckbox id="executives" checked={executives} title="Executives" onChange={this.onCheckboxesChanged} />
          {executives && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="includeLiteraryExecs"
                checked={includeLiteraryExecs}
                title="Include Literary "
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          {executives && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="includeTalentExecs"
                checked={includeTalentExecs}
                title="Include Talent "
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          <FilterCheckbox id="staff" checked={staff} title="Staff" onChange={this.onCheckboxesChanged} />
          {staff && (
            <React.Fragment>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="staffByPerson"
                  checked={staffByPerson}
                  title="Staff By Person"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="includeStaffAgencies"
                  checked={includeStaffAgencies}
                  title="Include Agencies"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            </React.Fragment>
          )}
          <FilterCheckbox id="cast" checked={cast} title="Cast" onChange={this.onCheckboxesChanged} />
          <FilterCheckbox
            id="literaryNotes"
            checked={literaryNotes}
            title="Literary Notes"
            onChange={this.onCheckboxesChanged}
          />
          {literaryNotes && (
            <>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="includeInternalLiteraryNotes"
                  checked={includeInternalLiteraryNotes}
                  title="Include Internal"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="includeDatesLiteraryNotes"
                  checked={includeDatesLiteraryNotes}
                  title="Include Dates"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            </>
          )}
          <FilterCheckbox
            id="talentNotes"
            checked={talentNotes}
            title="Talent Notes"
            onChange={this.onCheckboxesChanged}
          />
          {_hasAccess('printing', 'indieNotes') && (
            <FilterCheckbox
              id="indieNotes"
              checked={indieNotes}
              title="Indie Notes"
              onChange={this.onCheckboxesChanged}
            />
          )}
          <FilterCheckbox
            id="showRolesTable"
            checked={showRolesTable}
            title="Role Descriptions"
            onChange={this.onCheckboxesChanged}
          />
          {isCastingResults && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="showRolesSelected"
                disabled={!showRolesTable}
                checked={showRolesSelected}
                title="Selected Roles Only"
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          <div className={styles.subCheckbox}>
            {showRolesTable && (
              <FilterCheckbox
                id="showRolesTableAgency"
                checked={showRolesTableAgency}
                title="Competitive Agency"
                onChange={this.onCheckboxesChanged}
              />
            )}
          </div>
          {_hasAccess('printing', 'repAgreements') && (
            <FilterCheckbox
              id="repAgreements"
              checked={repAgreements}
              title="Rep Agreements"
              onChange={this.onCheckboxesChanged}
            />
          )}
          <FilterCheckbox id="budget" checked={budget} title="Budget" onChange={this.onCheckboxesChanged} />
          {budget && (
            <div className={styles.subCheckbox}>
              <FilterCheckbox
                id="budgetNotes"
                checked={budgetNotes}
                title="Budget Notes"
                onChange={this.onCheckboxesChanged}
              />
            </div>
          )}
          <FilterCheckbox id="agents" checked={agents} title="Agents" onChange={this.onCheckboxesChanged} />
          {agents && (
            <>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="talentAgents"
                  checked={talentAgents}
                  title="Talent Agents"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="literaryAgents"
                  checked={literaryAgents}
                  title="Literary Agents"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
              <div className={styles.subCheckbox}>
                <FilterCheckbox
                  id="indieAgents"
                  checked={indieAgents}
                  title="Indie Agents"
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.subHeader}>Formatting:</div>
        <div className={styles.container}>
          <FilterCheckbox
            id="clientBold"
            checked={!suppressFormatting}
            title="Bold Clients"
            onChange={() => this.onCheckboxesChanged('clientBold', suppressFormatting)}
          />
        </div>
        {_hasAccess('printing', 'watermark') && (
          <div className={styles.container}>
            <FilterCheckbox
              id="watermark"
              checked={watermark}
              title="Watermark Pages"
              onChange={this.onCheckboxesChanged}
            />
          </div>
        )}

        <div className={styles.subHeader}>Table of Contents:</div>
        {!indexOptionsDisabled && (
          <React.Fragment>
            <FormControl>
              <RadioGroup aria-label="indices" name="indices" value={index} onChange={this.onIndexRadioGroupChanged}>
                {indexOptions.map(indexOption => {
                  const { type, label, hasAccess = true } = indexOption;
                  if (hasAccess) {
                    return (
                      <div key={type}>
                        <FormControlLabel
                          key={type}
                          value={type}
                          control={
                            <Radio
                              color="default"
                              icon={<RadioButtonUncheckedIcon fontSize="small" />}
                              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                              style={{
                                color: this.state.index === type ? colors.primary : colors.unselected,
                                paddingLeft: 10,
                                marginTop: -6,
                                marginBottom: -6,
                              }}
                            />
                          }
                          label={label}
                        />
                        {index === 'Talent_Agent_Index' && type === 'Talent_Agent_Index' && (
                          <div className={styles.subCheckbox}>
                            <FilterCheckbox
                              id="director"
                              checked={director}
                              title="Director"
                              onChange={this.onCheckboxesChanged}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                  return undefined;
                })}
              </RadioGroup>
            </FormControl>
            {!primaryIndexOptionDisabled ? (
              <div className={styles.inFormCheckbox}>
                <FilterCheckbox
                  id="firstElement"
                  checked={firstElement}
                  title={`Show project under primary ${indexDisplayName} only`}
                  onChange={this.onCheckboxesChanged}
                />
              </div>
            ) : (
              <div className={styles.spacer} />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const withStyles = styled({
  container: {
    marginLeft: 5,
  },
  subHeader: {
    marginTop: 10,
  },
  inFormCheckbox: {
    marginTop: 15,
    marginLeft: 8, // horizontally aligns with radio buttons
  },
  spacer: {
    marginTop: 37, // is same size of checkbox when present so print / cancel buttons don't jump upon appear
  },
  radioSelection: {
    paddingLeft: 10,
  },
  subCheckbox: {
    marginLeft: '20px',
  },
});

export default withStyles(PrintOptions);
