import moment from 'moment';

const transformMongoProjectFilters = filters => {
                                                  let filterString = '';

                                                  //   project types
                                                  if (filters?.types && filters?.types.length > 0) {
                                                    filterString += '(';
                                                    for (let i = 0; i < filters.types.length; i++) {
                                                      filterString += `type:"${filters.types[i]}"`;
                                                      if (i < filters.types.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // included project profile types
                                                  if (
                                                    filters.profiles &&
                                                    filters.profiles.include &&
                                                    filters?.profiles.include.length > 0
                                                  ) {
                                                    let includedProfiles = filters.profiles.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includedProfiles.length; i++) {
                                                      filterString += `projectProfiles:"${includedProfiles[i].name}"`;
                                                      if (i < includedProfiles.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // excluded project profile types
                                                  if (
                                                    filters.profiles &&
                                                    filters.profiles.exclude &&
                                                    filters?.profiles.exclude.length > 0
                                                  ) {
                                                    let excludeProfiles = filters.profiles.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeProfiles.length; i++) {
                                                      filterString += `NOT projectProfiles:"${excludeProfiles[i].name}"`;
                                                      if (i < excludeProfiles.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // TV Status include
                                                  if (
                                                    filters.tvStatus &&
                                                    filters.tvStatus.include &&
                                                    filters?.tvStatus.include.length > 0
                                                  ) {
                                                    let includeTvStatus = filters.tvStatus.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeTvStatus.length; i++) {
                                                      filterString += `tvStatus:"${includeTvStatus[i]}"`;
                                                      if (i < includeTvStatus.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude TV Status
                                                  if (
                                                    filters.tvStatus &&
                                                    filters.tvStatus.exclude &&
                                                    filters?.tvStatus.exclude.length > 0
                                                  ) {
                                                    let excludeTvStatus = filters.tvStatus.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeTvStatus.length; i++) {
                                                      filterString += `NOT tvStatus:"${excludeTvStatus[i]}"`;
                                                      if (i < excludeTvStatus.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //networks include
                                                  if (
                                                    filters.networks &&
                                                    filters.networks.include &&
                                                    filters?.networks.include.length > 0
                                                  ) {
                                                    let includedNetworks = filters.networks.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includedNetworks.length; i++) {
                                                      filterString += `networks.name:"${includedNetworks[i].name}"`;
                                                      if (i < includedNetworks.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // excluded networks
                                                  if (
                                                    filters.networks &&
                                                    filters.networks.exclude &&
                                                    filters?.networks.exclude.length > 0
                                                  ) {
                                                    let excludeNetworks = filters.networks.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeNetworks.length; i++) {
                                                      filterString += `NOT networks.name:"${excludeNetworks[i].name}"`;
                                                      if (i < excludeNetworks.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //networks include
                                                  if (
                                                    filters.studios &&
                                                    filters.studios.include &&
                                                    filters?.studios.include.length > 0
                                                  ) {
                                                    let includedStudios = filters.studios.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includedStudios.length; i++) {
                                                      filterString += `studios.name:"${includedStudios[i].name}"`;
                                                      if (i < includedStudios.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude studios
                                                  if (
                                                    filters.studios &&
                                                    filters.studios.exclude &&
                                                    filters?.studios.exclude.length > 0
                                                  ) {
                                                    let excludedStudios = filters.studios.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludedStudios.length; i++) {
                                                      filterString += `NOT studios.name:"${excludedStudios[i].name}"`;
                                                      if (i < excludedStudios.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // Production include
                                                  if (
                                                    filters.productionCompanies &&
                                                    filters.productionCompanies.include &&
                                                    filters?.productionCompanies.include.length > 0
                                                  ) {
                                                    let includeProductions = filters.productionCompanies.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeProductions.length; i++) {
                                                      filterString += `productionCompanies.name:"${includeProductions[i].name}"`;
                                                      if (i < includeProductions.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude Production
                                                  if (
                                                    filters.productionCompanies &&
                                                    filters.productionCompanies.exclude &&
                                                    filters?.productionCompanies.exclude.length > 0
                                                  ) {
                                                    let excludeProductionCompanies =
                                                      filters.productionCompanies.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeProductionCompanies.length; i++) {
                                                      filterString += `NOT productionCompanies.name:"${excludeProductionCompanies[i].name}"`;
                                                      if (i < excludeProductionCompanies.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // distribution include
                                                  if (
                                                    filters.distribution &&
                                                    filters.distribution.include &&
                                                    filters?.distribution.include.length > 0
                                                  ) {
                                                    let includeDistributions = filters.distribution.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeDistributions.length; i++) {
                                                      filterString += `networks.distribution:"${includeDistributions[i]}"`;
                                                      if (i < includeDistributions.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude distribution
                                                  if (
                                                    filters.distribution &&
                                                    filters.distribution.exclude &&
                                                    filters?.distribution.exclude.length > 0
                                                  ) {
                                                    let excludeDistributions = filters.productionCompanies.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeDistributions.length; i++) {
                                                      filterString += `NOT networks.distribution:"${excludeDistributions[i]}"`;
                                                      if (i < excludeDistributions.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //PERSON EXEC STAFF AND TALENT FILTER
                                                  if (
                                                    filters.associatedNames &&
                                                    filters.associatedNames.include &&
                                                    filters.associatedNames.include.length > 0
                                                  ) {
                                                    let includeAssociatedNames = filters.associatedNames.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeAssociatedNames.length; i++) {
                                                      let includeAssociatedName = includeAssociatedNames[i];

                                                      if (includeAssociatedName.custom === 'All') {
                                                        filterString += `persons:"${includeAssociatedName.name}"`;
                                                      } else if (includeAssociatedName.custom === 'Assigned Projects') {
                                                        filterString += `associatedAssignedProjectNames:"${includeAssociatedName.name}"`;
                                                      } else if (includeAssociatedName.custom === 'Added Notes') {
                                                        filterString += `associatedAddedNoteNames:"${includeAssociatedName.name}"`;
                                                      }
                                                      if (i < includeAssociatedNames.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  if (filters.active) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(active:true)`;
                                                  }
                                                  if (filters.notActive) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(active:false)`;
                                                  }
                                                  if (filters.hasScript) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(status:"Has Script")`;
                                                  }
                                                  if (filters.newInformationOnly) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(status:"New Information Only")`;
                                                  }

                                                  if (filters.locationState) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(locationState:"${filters.locationState}")`;
                                                  }

                                                  if (filters.classification) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(classification:"${filters.classification}")`;
                                                  }

                                                  // parentCompanies include
                                                  if (
                                                    filters.parentCompanies &&
                                                    filters.parentCompanies.include &&
                                                    filters?.parentCompanies.include.length > 0
                                                  ) {
                                                    let includeParentCompanies = filters.parentCompanies.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeParentCompanies.length; i++) {
                                                      filterString += `parentCompanies.name:"${includeParentCompanies[i].name}"`;
                                                      if (i < includeParentCompanies.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude parentCompanies
                                                  if (
                                                    filters.parentCompanies &&
                                                    filters.parentCompanies.exclude &&
                                                    filters?.parentCompanies.exclude.length > 0
                                                  ) {
                                                    let excludeParentCompanies = filters.parentCompanies.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeParentCompanies.length; i++) {
                                                      filterString += `NOT parentCompanies.name:"${excludeParentCompanies[i].name}"`;
                                                      if (i < excludeParentCompanies.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // promoters include
                                                  if (
                                                    filters.promoters &&
                                                    filters.promoters.include &&
                                                    filters?.promoters.include.length > 0
                                                  ) {
                                                    let includePromoters = filters.promoters.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includePromoters.length; i++) {
                                                      filterString += `promoters.name:"${includePromoters[i].name}"`;
                                                      if (i < includePromoters.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude parentCompanies
                                                  if (
                                                    filters.promoters &&
                                                    filters.promoters.exclude &&
                                                    filters?.promoters.exclude.length > 0
                                                  ) {
                                                    let promoters = filters.promoters.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < promoters.length; i++) {
                                                      filterString += `NOT promoters.name:"${promoters[i].name}"`;
                                                      if (i < promoters.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  if (filters.capacity) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(capacity:"${
                                                      ['10k-20k', '25k-50k', '50k-100k'].includes(filters.capacity)
                                                        ? '0' + filters.capacity
                                                        : filters.capacity
                                                    }")`;
                                                  }

                                                  if (filters.budget) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(budget:"${filters.budget}")`;
                                                  }

                                                  if (filters.genre) {
                                                    filterString = concatFilters(filterString);
                                                    filterString += `(genre:"${filters.genre}")`;
                                                  }

                                                  //Project date filter
                                                  if (
                                                    (filters.projectDateStart || filterString.projectDateEnd) &&
                                                    filters.projectSearchDateType === undefined
                                                  ) {
                                                    let startDate = filters.projectDateStart
                                                      ? moment.utc(filters.projectDateStart).valueOf()
                                                      : null;
                                                    let endDate = filters.projectDateEnd
                                                      ? moment.utc(filters.projectDateEnd).valueOf()
                                                      : null;
                                                    if (startDate && endDate) {
                                                      filterString = concatFilters(filterString);
                                                      filterString += `(startedAt_timestamp:${startDate} TO ${endDate})`;
                                                    } else if (startDate && !endDate) {
                                                      filterString = concatFilters(filterString);
                                                      filterString += `(startedAt_timestamp}:${startDate})`;
                                                    } else if (!startDate && endDate) {
                                                      filterString = concatFilters(filterString);
                                                      filterString += `(startedAt_timestamp:${endDate})`;
                                                    }
                                                  }
                                                  if (filters.projectSearchDateType) {
                                                    let dateType = '';
                                                    if (filters.projectSearchDateType === 'Last Updated') {
                                                      dateType = 'lastUpdatedAt_timestamp';
                                                    } else if (filters.projectSearchDateType === 'Start Date') {
                                                      dateType = 'startedAt_timestamp';
                                                    } else if (filters.projectSearchDateType === 'Created') {
                                                      dateType = 'createdAt_timestamp';
                                                    }

                                                    let startDate = filters.projectDateStart
                                                      ? moment.utc(filters.projectDateStart).valueOf()
                                                      : null;
                                                    let endDate = filters.projectDateEnd
                                                      ? moment.utc(filters.projectDateEnd).valueOf()
                                                      : null;

                                                    if (startDate && endDate) {
                                                      filterString = concatFilters(filterString);
                                                      filterString += `(${dateType}:${startDate} TO ${endDate})`;
                                                    } else if (startDate && !endDate) {
                                                      filterString = concatFilters(filterString);
                                                      filterString += `(${dateType}:${startDate})`;
                                                    } else if (!startDate && endDate) {
                                                      filterString = concatFilters(filterString);
                                                      filterString += `(${dateType}:${endDate})`;
                                                    }
                                                  }

                                                  // salesFinancing include
                                                  if (
                                                    filters.salesFinancing &&
                                                    filters.salesFinancing.include &&
                                                    filters?.salesFinancing.include.length > 0
                                                  ) {
                                                    let includeSalesFinancing = filters.salesFinancing.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeSalesFinancing.length; i++) {
                                                      filterString += `salesFinancing.name:"${includeSalesFinancing[i].name}"`;
                                                      if (i < includeSalesFinancing.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude salesFinancing
                                                  if (
                                                    filters.salesFinancing &&
                                                    filters.salesFinancing.exclude &&
                                                    filters?.salesFinancing.exclude.length > 0
                                                  ) {
                                                    let excludeSalesFinancing = filters.salesFinancing.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeSalesFinancing.length; i++) {
                                                      filterString += `NOT salesFinancing.name:"${excludeSalesFinancing[i].name}"`;
                                                      if (i < excludeSalesFinancing.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //indie status include
                                                  if (
                                                    filters.indieStatus &&
                                                    filters.indieStatus.include &&
                                                    filters?.indieStatus.include.length > 0
                                                  ) {
                                                    let includeIndieStatus = filters.indieStatus.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeIndieStatus.length; i++) {
                                                      filterString += `indieStatus:"${includeIndieStatus[i]}"`;
                                                      if (i < includeIndieStatus.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude indiestatus
                                                  if (
                                                    filters.indieStatus &&
                                                    filters.indieStatus.exclude &&
                                                    filters?.indieStatus.exclude.length > 0
                                                  ) {
                                                    let excludeIndieStatus = filters.indieStatus.exclude;
                                                    filterString = concatFilters(filterString);
                                                    filterString += '(';
                                                    for (let i = 0; i < excludeIndieStatus.length; i++) {
                                                      filterString += `NOT indieStatus:"${excludeIndieStatus[i]}"`;
                                                      if (i < excludeIndieStatus.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  // include repAgreementStatus
                                                  if (
                                                    filters.repAgreementStatus &&
                                                    filters.repAgreementStatus.include &&
                                                    filters?.repAgreementStatus.include.length > 0
                                                  ) {
                                                    let includeRepAgreementStatus = filters.repAgreementStatus.include;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < includeRepAgreementStatus.length; i++) {
                                                      filterString += `repAgreementStatuses:"${includeRepAgreementStatus[i]}"`;
                                                      if (i < includeRepAgreementStatus.length - 1) {
                                                        filterString += ' OR ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  //exclude repAgreementStatus
                                                  if (
                                                    filters.repAgreementStatus &&
                                                    filters.repAgreementStatus.exclude &&
                                                    filters?.repAgreementStatus.exclude.length > 0
                                                  ) {
                                                    let excludeRepAgreementStatus = filters.repAgreementStatus.exclude;
                                                    filterString = concatFilters(filterString);

                                                    filterString += '(';
                                                    for (let i = 0; i < excludeRepAgreementStatus.length; i++) {
                                                      filterString += `NOT repAgreementStatuses:"${excludeRepAgreementStatus[i]}"`;
                                                      if (i < excludeRepAgreementStatus.length - 1) {
                                                        filterString += ' AND ';
                                                      }
                                                    }
                                                    filterString += ')';
                                                  }

                                                  return String(filterString);
                                                };

const transformMongoCastingFilters = filters => {
  let filterString = '';

  // project types
  if (filters?.projectTypes && filters?.projectTypes.length > 0) {
    filterString += '(';
    for (let i = 0; i < filters.projectTypes.length; i++) {
      filterString += `projectType:"${filters.projectTypes[i]}"`;
      if (i < filters.projectTypes.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  // casting types
  if (filters?.types && filters?.types.length > 0) {
    filterString = concatFilters(filterString);
    filterString += '(';
    for (let i = 0; i < filters.types.length; i++) {
      filterString += `type:"${filters.types[i]}"`;
      if (i < filters.types.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  // casting status
  if (filters?.statuses && filters?.statuses.length > 0) {
    filterString = concatFilters(filterString);
    filterString += '(';
    for (let i = 0; i < filters.statuses.length; i++) {
      filterString += `status:"${filters.statuses[i]}"`;
      if (i < filters.statuses.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  // casting gender
  if (filters.gender) {
    filterString = concatFilters(filterString);
    filterString += `(gender:"${filters.gender}")`;
  }

  // casting ethnicity
  if (filters?.ethnicities && filters?.ethnicities.length > 0) {
    filterString = concatFilters(filterString);
    filterString += '(';
    for (let i = 0; i < filters.ethnicities.length; i++) {
      filterString += `ethnicity:"${filters.ethnicities[i]}"`;
      if (i < filters.ethnicities.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  // ageMin
  if (filters.ageMin) {
    filterString = concatFilters(filterString);
    filterString += `(ageMin >= ${filters.ageMin})`;
  }

  // ageMax
  if (filters.ageMax) {
    filterString = concatFilters(filterString);
    filterString += `(ageMax <= ${filters.ageMax})`;
  }

  // networks include
  if (filters.networks && filters.networks.include && filters?.networks.include.length > 0) {
    let includenetworks = filters.networks.include;
    filterString = concatFilters(filterString);

    filterString += '(';
    for (let i = 0; i < includenetworks.length; i++) {
      filterString += `networks.name:"${includenetworks[i].name}"`;
      if (i < includenetworks.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  // studios include
  if (filters.studios && filters.studios.include && filters?.studios.include.length > 0) {
    let includestudios = filters.studios.include;
    filterString = concatFilters(filterString);

    filterString += '(';
    for (let i = 0; i < includestudios.length; i++) {
      filterString += `studios.name:"${includestudios[i].name}"`;
      if (i < includestudios.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  // production Company include
  if (
    filters.productionCompanies &&
    filters.productionCompanies.include &&
    filters?.productionCompanies.include.length > 0
  ) {
    let includeproductionCompanies = filters.productionCompanies.include;
    filterString = concatFilters(filterString);

    filterString += '(';
    for (let i = 0; i < includeproductionCompanies.length; i++) {
      filterString += `productionCompanies.name:"${includeproductionCompanies[i].name}"`;
      if (i < includeproductionCompanies.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  // distribution
  if (filters.distribution && filters.distribution.include && filters?.distribution.include.length > 0) {
    let includeDistribution = filters.distribution.include;
    filterString = concatFilters(filterString);
    filterString += '(';
    for (let i = 0; i < includeDistribution.length; i++) {
      filterString += `studios.distribution:"${includeDistribution[i]}"`;
      if (i < includeDistribution.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  if (filters?.clientRolesOnly) {
    filterString = concatFilters(filterString);
    filterString += `(talent.type: 'Client')`;
  }

  if (!filters?.includeArchivedCastings || filters.excludeArchivedRoles) {
    filterString = concatFilters(filterString);
    filterString += `(active:true)`;
  }

  if (!filters?.includeArchivedProjects) {
    filterString = concatFilters(filterString);
    filterString += `(isProjectActive:true)`;
  }

  if (filters?.newInformationOnly) {
    filterString = concatFilters(filterString);
    filterString += `(newInfo:true)`;
  }

  // profiles
  if (filters.profiles && filters.profiles.include && filters?.profiles.include.length > 0) {
    let includedProfiles = filters.profiles.include;
    filterString = concatFilters(filterString);

    filterString += '(';
    for (let i = 0; i < includedProfiles.length; i++) {
      filterString += `projectProfiles:"${includedProfiles[i].name}"`;
      if (i < includedProfiles.length - 1) {
        filterString += ' OR ';
      }
    }
    filterString += ')';
  }

  //casting date filter
  if (filters.castingDateType) {
    let dateType = '';
    if (filters.castingDateType === 'Last Updated') {
      dateType = 'lastUpdatedAt_timestamp';
    } else if (filters.castingDateType === 'Start Date') {
      dateType = 'startedAt_timestamp';
    } else if (filters.castingDateType === 'Created') {
      dateType = 'createdAt_timestamp';
    }

    let startDate = filters.castingDateStart ? moment.utc(filters.castingDateStart).valueOf() : null;
    let endDate = filters.castingDateEnd ? moment.utc(filters.castingDateEnd).valueOf() : null;

    if (startDate && endDate) {
      filterString = concatFilters(filterString);
      filterString += `(${dateType}:${startDate} TO ${endDate})`;
    } else if (startDate && !endDate) {
      filterString = concatFilters(filterString);
      filterString += `(${dateType}>${startDate})`;
    } else if (!startDate && endDate) {
      filterString = concatFilters(filterString);
      filterString += `(${dateType}<${endDate})`;
    }
  }

  if (filterString === '(active:true) AND (isProjectActive:true)') {
    filterString = '(searchActive:true)';
  }

  return String(filterString);
};

const concatFilters = filterString => {
  if (filterString.length > 0) {
    return (filterString += ' AND ');
  } else {
    return filterString;
  }
};

export { transformMongoProjectFilters, transformMongoCastingFilters };
